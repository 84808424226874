<p-dialog
  header="Validar Fatura"
  [(visible)]="showModal"
  [modal]="true"
  [style]="{ width: '90vw' }"
  [closable]="false"
  [resizable]="false"
>
  <form autocomplete="off" [formGroup]="documentForm">
    <div class="p-fluid p-formgrid p-grid">
      <p-messages></p-messages>

      <div class="p-col-12" *ngIf="canAccept">
        <hr />

        <div formArrayName="tbItensDocumentoGerados">
          <div class="p-grid p-mt-2">
            <label class="p-col p-text-bold">
              Subtotal<b class="red-text">*</b>
            </label>

            <label class="p-col p-text-bold">
              Taxa (IVA)<b class="red-text">*</b>
            </label>

            <label class="p-col p-text-bold">
              Código Incidência<b class="red-text">*</b>
            </label>

            <label class="p-col p-text-bold">
              IVA<b class="red-text">*</b>
            </label>

            <label class="p-col p-text-bold">
              Total<b class="red-text">*</b>
            </label>

            <label class="p-col-fixed" [ngStyle]="{ width: '100px' }"> </label>
          </div>

          <div *ngFor="let item of items.controls; let i = index">
            <div [formGroupName]="i">
              <div class="p-grid">
                <div class="p-field p-col">
                  <p-inputNumber
                    mode="decimal"
                    [minFractionDigits]="2"
                    name="valorBaseTributavelItens"
                    formControlName="valorBaseTributavelItens"
                    class="p-inputtext-sm"
                    placeholder="Montante do item"
                    (onBlur)="calcItemTax(i)"
                  >
                  </p-inputNumber>

                  <app-message
                    [control]="items.at(i).get('valorBaseTributavelItens')"
                    error="required"
                    text="Informe o montante"
                  >
                  </app-message>
                </div>

                <div class="p-field p-col" formGroupName="tbTaxaAplicavel">
                  <input
                    pInputText
                    class="p-inputtext-sm"
                    name="descTaxaAplicavel"
                    formControlName="descTaxaAplicavel"
                    placeholder="Taxas"
                    (focus)="showTaxRate(i)"
                  />
                </div>

                <div class="p-field p-col" formGroupName="tbMotivoIsencao">
                  <input
                    pInputText
                    class="p-inputtext-sm"
                    name="tbMotivoIsencao"
                    formControlName="codigoMotivoIsencao"
                    placeholder="Motivo isenção/sujeição"
                    (focus)="showDisplayExemption(i)"
                  />
                </div>

                <div class="p-field p-col">
                  <p-inputNumber
                    class="p-inputtext-sm"
                    mode="decimal"
                    [minFractionDigits]="2"
                    name="valorImposto"
                    formControlName="valorImposto"
                    placeholder="Imposto"
                  >
                  </p-inputNumber>
                </div>

                <div class="p-field p-col">
                  <p-inputNumber
                    class="p-inputtext-sm"
                    mode="decimal"
                    [minFractionDigits]="2"
                    name="valorItens"
                    formControlName="valorItens"
                    placeholder="Total do item"
                  >
                  </p-inputNumber>
                </div>

                <div class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                  <button
                    type="button"
                    mdbBtn
                    color="danger"
                    size="sm"
                    pTooltip="Remover item"
                    tooltipPosition="left"
                    (click)="removeItem(i)"
                  >
                    <mdb-icon fas icon="trash"></mdb-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          mdbBtn
          color="info"
          size="sm"
          pTooltip="Adicionar linha"
          tooltipPosition="right"
          (click)="addItem()"
          [style]="{ margin: '5px 0 15px 0' }"
        >
          <mdb-icon fas icon="plus"></mdb-icon>
        </button>

        <hr />
      </div>

      <div class="p-field p-col-12">
        <label
          >Verificação do Sistema<b class="font-bold pink-text">*</b></label
        >
        <input
          type="text"
          pInputText
          name="desDocumentoGeradoConsumidor"
          formControlName="desDocumentoGeradoConsumidor"
        />
      </div>

      <div class="p-field p-col-12">
        <label>Notas do Técnico<b class="font-bold pink-text"></b></label>
        <p-editor formControlName="obsFinal" [style]="{ height: '220px' }">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button
                type="button"
                class="ql-italic"
                aria-label="Italic"
              ></button>
              <button
                type="button"
                class="ql-underline"
                aria-label="Underline"
              ></button>
            </span>
          </ng-template>
        </p-editor>
      </div>
    </div>
  </form>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      mdbWavesEffect
      (click)="toggleModal()"
    >
      FECHAR
    </button>

    <button
      type="button"
      mdbBtn
      color="danger"
      mdbWavesEffect
      pTooltip="Recusar fatura"
      tooltipPosition="top"
      (click)="refuseDocument()"
      [disabled]="
        documentForm.get('desDocumentoGeradoConsumidor').invalid ||
        validDocumentLoading
      "
    >
      RECUSAR
    </button>

    <button
      *ngIf="canAccept"
      type="submit"
      mdbBtn
      color="success"
      mdbWavesEffect
      pTooltip="Aceitar a fatura"
      tooltipPosition="top"
      (click)="acceptDocument()"
      [disabled]="documentForm.invalid || validDocumentLoading"
    >
      ACEITAR
    </button>
  </div>
  <div class="p-col-12">
    <p-progressBar
      *ngIf="validDocumentLoading"
      mode="indeterminate"
      [style]="{ height: '3px' }"
    >
    </p-progressBar>
  </div>
</p-dialog>

<p-dialog
  header="Selecione a taxa"
  modal="true"
  [(visible)]="displayTaxRate"
  [style]="{ width: '50vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="taxRates"
    selectionMode="single"
    [(selection)]="selectedTaxRate"
    (onRowSelect)="onTaxRateSelect($event)"
    [rows]="10"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '60%' }">Imposto</th>
        <th [style]="{ width: '20%' }">Sigla</th>
        <th [style]="{ width: '20%' }">Taxa</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.descricaoTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.siglaTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Taxa</span>
          {{ rowData.descTaxaAplicavel }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayTaxRate = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o código de isenção/sujeição"
  [(visible)]="displayExemption"
  [style]="{ width: '70vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="exemptionReasons"
    selectionMode="single"
    (onRowSelect)="onExemptionSelect($event)"
    [paginator]="true"
    [rows]="5"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Código</th>
        <th [style]="{ width: '40%' }">Descrição</th>
        <th [style]="{ width: '40%' }">Artigo</th>
        <th>Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{ rowData.codigoMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{ rowData.desMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Artigo</span>
          {{ rowData.artigo }}
        </td>
        <td>
          <span class="p-column-title">Situação</span>
          {{ rowData.isento ? "Isenção" : "Sujeição" }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayExemption = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>
