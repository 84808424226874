import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { DocumentConsumer } from '../core/models/document-consumer';
import { AppHttp } from './../auth/app-http';
import { buildCurrentDate } from '../shared/date';
import { DocumentConsumerRegistrationStatus } from '../core/models/document-consumer-registration';

export class DocumentConsumerRegConsumerFilter {
  page = 0;
  rows = 15;

  nifEmissor: string;
  nomeEmissor: string;
  nifConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentConsumerRegConsumerService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGeradoConsumidor`;
  }

  create(document): Promise<DocumentConsumer> {
    const formData = new FormData();
    document.files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('documentoGeradoConsumidor', JSON.stringify(document));

    return this.http
      .post<DocumentConsumer>(`${this.API_URL}`, formData)
      .toPromise();
  }

  async read(filter: DocumentConsumerRegConsumerFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifEmissor)
      params = params.append('nifEmissor', filter.nifEmissor);

    if (filter.nomeEmissor)
      params = params.append('nomeEmissor', filter.nomeEmissor);

    if (filter.dtEmissaoDocumentoDe)
      params = params.append(
        'dtEmissaoDocumentoDe',
        moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD')
      );

    if (filter.dtEmissaoDocumentoAte)
      params = params.append(
        'dtEmissaoDocumentoAte',
        moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD')
      );

    const response = await this.http
      .get<any>(`${this.API_URL}/porConsumidor`, { params })
      .toPromise();
    return {
      body: <Document[]>response.content,
      total: response.totalElements,
    };
  }

  async get(id): Promise<DocumentConsumer> {
    const response = await this.http
      .get<DocumentConsumer>(`${this.API_URL}/${id}`)
      .toPromise();
    const documentView = response as DocumentConsumer;
    this.convertFields([documentView]);
    return documentView;
  }

  private convertFields(documentsView: DocumentConsumer[]) {
    documentsView.forEach((documentView) => {
      if (documentView.dtCria) {
        documentView.dtCria = moment(
          documentView.dtCria,
          'YYYY-MM-DD'
        ).toDate();
      }

      if (documentView.dtAlter) {
        documentView.dtAlter = moment(
          documentView.dtAlter,
          'YYYY-MM-DD'
        ).toDate();
      }

      if (documentView.dtEmissaoDocumento) {
        documentView.dtEmissaoDocumento = moment(
          documentView.dtEmissaoDocumento,
          'YYYY-MM-DD'
        ).toDate();
      }
    });
  }

  getDocumentValidationStatusColor(
    status: DocumentConsumerRegistrationStatus
  ): string {
    switch (status) {
      case DocumentConsumerRegistrationStatus.PENDENTE:
        return 'blue';
      case DocumentConsumerRegistrationStatus.DIVERGENTE:
        return 'warning';
      case DocumentConsumerRegistrationStatus.RECUSADO:
        return 'danger';
      case DocumentConsumerRegistrationStatus.APROVADO:
        return 'success';
      default:
        return 'blue';
    }
  }
}
