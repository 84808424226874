import { Injectable } from '@angular/core';
import { AppHttp } from '../auth/app-http';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export class TaxRateFilter {}

@Injectable({
  providedIn: 'root',
})
export class TaxRateService {
  API_URL: string;
  ivaTaxRegimeUrl: string = environment.sitIvaUrl;
  ivaTaxRegimeToken: string = environment.sitIvaToken;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/taxaAplicavel`;
  }

  getFilterParams(filter: TaxRateFilter) {
    let params = new HttpParams();
    return params;
  }

  async read(): Promise<any> {
    return this.http.get<any>(this.API_URL).toPromise();
  }

  readActives(date: string, emitterNif?: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('dataFaturacao', date);
    if (emitterNif) {
      params = params.append('nifEmitente', emitterNif);
    }
    return this.http.get(`${this.API_URL}`, { params }).toPromise();
  }

  readAllActives(): Promise<any> {
    return this.http.get(`${this.API_URL}`).toPromise();
  }

  readByTypeTax(typeTaxId: number): Promise<any> {
    return this.http.get(`${this.API_URL}/${typeTaxId}`).toPromise();
  }

  dropdownISC(date: string) {
    return this.readActives(date).then((response) => {
      const iscFilter = response.filter(
        (r) => r.tbTipoImposto.siglaTipoImposto === 'ISC'
      );
      return iscFilter.map((i) => ({
        label: `${i.tbTipoImposto.siglaTipoImposto} - ${i.descTaxaAplicavel}`,
        value: {
          idTaxaAplicavel: i.idTaxaAplicavel,
          valorTaxaAplicavel: i.valorTaxaAplicavel,
        },
      }));
    });
  }

  async create(body) {
    return this.http.post(this.API_URL, body).toPromise();
  }

  async update(id: number, body) {
    return this.http.put(`${this.API_URL}/${id}`, body).toPromise();
  }

  async changeStatus(id: number, status: boolean) {
    return this.http
      .patch(`${this.API_URL}/${id}?status=${status}`, null)
      .toPromise();
  }

  async getIvaTaxRegime(nif: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.ivaTaxRegimeToken}`
    });

    const formData = new FormData();
    formData.append('contri_id', nif);

    return this.http.post(this.ivaTaxRegimeUrl, formData, {headers}).toPromise();
  }
}
