import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from './../shared/shared.module';
import { CreateComponent } from './create/create.component';
import { DocumentOnlineEmitterRouter } from './document-online-emitter-router';
import { EditComponent } from './edit/edit.component';
import { GetComponent } from './get/get.component';
import { IndexComponent } from './index/index.component';
import { ModalComponent } from './modal/modal.component';
import { ReadComponent } from './read/read.component';
import { ViewDialogComponent } from './view-dialog/view-dialog.component';

@NgModule({
  declarations: [
    CreateComponent,
    ReadComponent,
    GetComponent,
    ViewDialogComponent,
    IndexComponent,
    EditComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    DocumentOnlineEmitterRouter,

    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    PDFExportModule,
    SharedModule,

    TableModule,
    InputTextModule,
    TooltipModule,
    CalendarModule,
    ToggleButtonModule,
    InputTextareaModule,
    ProgressBarModule,
    FileUploadModule,
    DialogModule,
    OverlayPanelModule,
    DropdownModule,
    InputMaskModule,
    CurrencyMaskModule,
    InputNumberModule,
    AutoCompleteModule,
    EditorModule,
    InputSwitchModule
  ],
  exports: [
    ViewDialogComponent
  ]
})
export class DocumentOnlineEmitterModule { }
