<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EMITENTE
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TRANSPORTAR SÉRIE DE FORMA MANUAL
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <span class="text-info">
          <mdb-icon fas icon="info-circle"></mdb-icon>
        </span>
        Nesta tela o emitente pode transportar todas as suas séries que deseja
        continuar a utilizar no ano 2022.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <p-dropdown
                    inputId="dropdown"
                    class="p-inputtext-sm"
                    [autoDisplayFirst]="false"
                    [options]="typesDocument"
                    name="typeDocument"
                    [(ngModel)]="filter.typeDocument"
                    optionLabel="name"
                    [showClear]="true"
                  >
                  </p-dropdown>
                  <label for="dropdown">Tipo Documento</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-3">
                <span class="p-float-label">
                  <input
                    pInputText
                    class="p-inputtext-sm"
                    name="serieNumber"
                    [(ngModel)]="filter.serieNumber"
                  />
                  <label for="float-input"
                    >Número de Série (ex.FR0000120)</label
                  >
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input
                    pInputText
                    class="p-inputtext-sm"
                    name="description"
                    [(ngModel)]="filter.description"
                  />
                  <label for="float-input">Descrição</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <button
                  type="submit"
                  mdbBtn
                  color="pink"
                  size="sm"
                  class="btn-block btn-filter"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table
          styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
          [value]="emissionDocuments"
          [loading]="loading"
          [paginator]="true"
          [rows]="filter.rows"
          [lazy]="true"
          [totalRecords]="total"
          dataKey="idPedidoEmissaoDocumento"
          editMode="row"
          (onLazyLoad)="onChangePage($event)"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Tipo Documento</th>
              <th>Série</th>
              <th>Total Solicitado</th>
              <th>Data de Solicitação</th>
              <th>Descrição</th>
              <th [style]="{ width: '11%' }"></th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-rowData
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo Documento</span>
                {{ rowData.tipoDocumento }}
              </td>

              <td>
                <span class="p-column-title">Série</span>
                {{ rowData.siglaSerie + "" + rowData.serie + "" + rowData.ano }}
              </td>

              <td>
                <span class="p-column-title">Total Solicitado</span>
                {{ rowData.totalJaSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Data de Solicitação</span>
                {{ rowData.data | date : "dd/MM/yyyy" }}
              </td>

              <td>
                <span class="p-column-title">Descrição</span>
                {{ rowData.descricao }}
              </td>

              <td [style]="{ padding: '1px 4px' }">
                <button
                  type="button"
                  mdbBtn
                  color="info"
                  size="sm"
                  tooltipPosition="left"
                  pTooltip="Transportar para o próximo ano"
                  (click)="showDialog(rowData)"
                >
                  Transportar
                  <mdb-icon fas icon="arrow-right"></mdb-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Transportar para o próximo ano"
  [(visible)]="display"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: '550px', minWidth: '200px' }"
  [minY]="70"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <form #formTransport="ngForm">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Série a Transportar<strong class="red-text">*</strong></label>
        <input
          pInputText
          type="text"
          name="serieToTransport"
          [(ngModel)]="serieToTransport"
          required
          disabled
        />
      </div>

      <div class="p-field p-col-12">
        <label>Transportar para o ano<strong class="red-text">*</strong></label>
        <p-dropdown
          [options]="yearsToTransport"
          name="yearToTransport"
          [showClear]="true"
          [(ngModel)]="yearToTransport"
          placeholder="Selecione o ano"
          required="required"
        >
        </p-dropdown>
      </div>

      <div class="p-field p-col-12">
        <label>Qtd. documentos</label>
        <input
          pInputText
          type="text"
          name="qtDocumentosSolicitado"
          [(ngModel)]="emissionDocument.qtDocumentosSolicitado"
          required
        />
      </div>

      <div class="p-field p-col-12">
        <label>Máscara</label>
        <input
          pInputText
          type="text"
          name="mascara"
          [(ngModel)]="emissionDocument.mascara"
          placeholder="Nº dígitos da sequência dos documentos"
          required
        />
      </div>

      <!-- <div class="p-field p-col-12">
        <label>Nº sequencial do primeiro documento</label>
        <input
          pInputText
          type="text"
          name="numInicialSequencial"
          [(ngModel)]="emissionDocument.numInicialSequencial"
          placeholder="Número inicial da sequência dos documentos"
          required
        />
      </div> -->

      <div class="p-field p-col-12">
        <label>Descrição (Opcional)</label>
        <input
          pInputText
          type="text"
          name="descPedidoEmissaoDocumento"
          [(ngModel)]="emissionDocument.descPedidoEmissaoDocumento"
          placeholder="Ex. Série para agência nº 1"
        />
      </div>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      size="md"
      color="light"
      (click)="display = false"
    >
      CANCELAR
    </button>

    <button
      type="button"
      mdbBtn
      size="md"
      color="primary"
      [disabled]="!formTransport.valid || loading"
      (click)="transport(formTransport)"
    >
      TRANSPORTAR
    </button>

    <div class="p-col-12">
      <p-progressBar
        *ngIf="loadingTransport"
        mode="indeterminate"
        [style]="{ height: '3px' }"
      ></p-progressBar>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayResult"
  [style]="{ width: '60vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
  modal="true"
>
  <div class="p-grid">
    <div class="col-md-12 text-center p-mt-3">
      <mdb-icon
        class="icon-message-lg text-success text-center"
        fas
        icon="check-circle"
      ></mdb-icon>
      <p class="bq-title text-center p-mt-2">Série transportada com sucesso!</p>
      <p class="bq-title text-center p-mt-0">
        As informações abaixo devem ser parametrizadas no seu software de
        facturação.
      </p>
    </div>

    <table mdbTable small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{ width: '30%' }">
            <strong>Número de Série</strong>
          </th>
          <td class="text-left">
            {{
              emissionDocumentResult.siglaDocAutorizacaoEmissao +
                "" +
                emissionDocumentResult.numSerieDocumento +
                "" +
                emissionDocumentResult.anoAutorizacaoEmissao
            }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Número de autorização</strong>
          </th>
          <td class="text-left">
            {{ emissionDocumentResult.numAutorizacaoEmissao }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Número de certificação</strong>
          </th>
          <td class="text-left">
            {{ emissionDocumentResult.numeroCertificacao }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-template pTemplate="footer">
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayResult = false"
    >
      FECHAR
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayBlockedSerieMessage"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [showHeader]="false"
>
  <div class="alert alert-danger" role="alert">
    <mdb-icon fas icon="info-circle"></mdb-icon>
    Sujeito passivo bloqueado para solicitação de novas séries. Deve comunicar
    as faturas em falta!
    <p class="p-mt-2">Nome: {{ blockedTaxpayerInfo?.nomePessoa }}</p>
    <p>NIF: {{ blockedTaxpayerInfo?.nifContribuinte }}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayBlockedSerieMessage = false"
      label="Ok"
      styleClass="p-button-text"
    >
    </p-button>
  </ng-template>
</p-dialog>