<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/documento">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          DOCUMENTO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/adquirente">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          ADQUIRENTE
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          REGISTAR DOCUMENTO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <mdb-icon fas icon="info-circle"></mdb-icon>
      Esta funcionalidade permite aos adquirentes comunicarem as suas facturas e
      outros documentos equivalentes que não foram comunicados pelos seus
      fornecedores.
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" [formGroup]="documentForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Emitente <b class="font-bold col-pink">*</b></label>
              <input
                pInputText
                name="emitterNif"
                formControlName="nifEmissor"
                (input)="onInputNif($event)"
              />

              <app-message
                [control]="documentForm.get('nifEmissor')"
                error="required"
                text="Informe o NIF do Emitente"
              >
              </app-message>

              <app-message
                [control]="documentForm.get('nifEmissor')"
                error="minlength"
                text="NIF inválido"
              >
              </app-message>

              <div *ngIf="!validNif" class="ui-message ui-message-error">
                NIF inexistente
              </div>
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label
                >Nome do Emitente <b class="font-bold col-pink">*</b></label
              >
              <input
                pInputText
                name="nomeEmissor"
                formControlName="nomeEmissor"
              />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label
                >Referência do Documento
                <b class="font-bold col-pink">*</b></label
              >
              <input
                pInputText
                name="numDocumento"
                formControlName="numDocumento"
              />

              <app-message
                [control]="documentForm.get('numDocumento')"
                error="required"
                text="Informe o número  do documento"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Data de Emissão <b class="font-bold col-pink">*</b></label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="dtEmissaoDocumento"
                formControlName="dtEmissaoDocumento"
                placeholder="dd/mm/aaaa"
                (onSelect)="showTaxSigla($event)"
              >
              </p-calendar>

              <app-message
                [control]="documentForm.get('dtEmissaoDocumento')"
                error="required"
                text="Informe a data de emissão"
              >
              </app-message>
            </div>

            <div class="p-col-12">
              <hr />

              <div formArrayName="items">
                <div class="p-grid p-mt-2">
                  <label
                    class="p-col-fixed p-text-bold"
                    [ngStyle]="{ width: '300px' }"
                  >
                    Descrição (opcional)
                  </label>

                  <label class="p-col p-text-bold">
                    Subtotal<b class="red-text">*</b>
                  </label>

                  <label
                    class="p-col p-text-bold"
                    [ngStyle]="{ width: '200px' }"
                  >
                    Taxa ({{ siglaTipoImpostoToShow }})<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    {{ siglaTipoImpostoToShow }}<b class="red-text">*</b>
                  </label>

                  <label class="p-col p-text-bold">
                    Total<b class="red-text">*</b>
                  </label>

                  <label class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                  </label>
                </div>

                <div *ngFor="let item of items.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div
                        class="p-field p-col-fixed"
                        [ngStyle]="{ width: '300px' }"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descItens"
                          formControlName="descItens"
                          maxlength="100"
                          placeholder="Descrição (máximo 100 caracteres)"
                        />
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorBaseTributavelItens"
                          formControlName="valorBaseTributavelItens"
                          class="p-inputtext-sm"
                          placeholder="Montante do item"
                          (onBlur)="calcItemTax(i)"
                        >
                        </p-inputNumber>

                        <app-message
                          [control]="
                            items.at(i).get('valorBaseTributavelItens')
                          "
                          error="required"
                          text="Informe o montante"
                        >
                        </app-message>
                      </div>

                      <div
                        class="p-field p-col"
                      >
                        <p-dropdown
                          class="p-inputtext-sm"
                          [options]="taxRates"
                          placeholder="Selecione uma Taxa"
                          name="valorTaxaAplicavel"
                          formControlName="valorTaxaAplicavel"
                          (onChange)="onTaxRateSelect($event, i)"
                          required
                          [ngStyle]="{ width: '200px' }"
                        >
                        </p-dropdown>
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorImposto"
                          formControlName="valorImposto"
                          placeholder="Imposto"
                        >
                        </p-inputNumber>
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="2"
                          name="valorItens"
                          formControlName="valorItens"
                          placeholder="Total do item"
                        >
                        </p-inputNumber>
                      </div>

                      <div class="p-col-fixed" [ngStyle]="{ width: '100px' }">
                        <button
                          type="button"
                          mdbBtn
                          color="danger"
                          size="sm"
                          pTooltip="Remover item"
                          tooltipPosition="left"
                          (click)="removeItem(i)"
                        >
                          <mdb-icon fas icon="trash"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                (click)="addItem()"
                [style]="{ margin: '5px 0 15px 0' }"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr />
            </div>

            <div class="p-field p p-col-12 p-md-4">
              <label>Montante <b class="font-bold col-pink">*</b></label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValorBaseTributavel"
                formControlName="totalValorBaseTributavel"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>{{ siglaTipoImpostoToShow }}</label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalImposto"
                formControlName="totalImposto"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Total <b class="font-bold col-pink">*</b></label>
              <p-inputNumber
                class="p-inputtext-sm"
                mode="decimal"
                [minFractionDigits]="2"
                name="totalValor"
                formControlName="totalValor"
              >
              </p-inputNumber>
            </div>

            <div class="p-col-12">
              <label>Ficheiros<b class="font-bold col-pink">*</b> </label>
              <p-fileUpload
                name="file"
                [multiple]="true"
                accept=".png, .jpeg, .jpg, .pdf"
                maxFileSize="100000000"
                customUpload="true"
                chooseLabel="Adicionar"
                cancelLabel="Cancelar"
                (onSelect)="onSelectImage($event.files)"
                (onRemove)="onDeleteImage()"
                (onClear)="onDeleteImage()"
                [showUploadButton]="false"
                #fileUpload
                required
              >
                <ng-template pTemplate="content"> </ng-template>
              </p-fileUpload>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/adquirente"
              >
                VOLTAR
              </button>

              <button
                type="submit"
                color="success"
                mdbBtn
                mdbWavesEffect
                [disabled]="documentForm.invalid || loading"
              >
                SALVAR
              </button>
            </div>

            <div class="p-col-12">
              <p-progressBar
                *ngIf="loading"
                mode="indeterminate"
                [style]="{ height: '3px' }"
              >
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
