import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmissionDocumentResult } from 'src/app/core/models/emission-document';
import { environment } from 'src/environments/environment';
import { AppHttp } from './../auth/app-http';

export class AuthorizationFilter {
  page = 0;
  rows = 15;

  authorizationNumber: string;
  serieNumber: string;
  consumerNif: string;
  emitterNif: string;

  status: boolean;

  alterDate: Date;
  initialDate: Date;
  finalDate: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/autorizacaoEmissao`;
  }

  readTotalSerieForeachTaxpayer(filter: AuthorizationFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());


    return this.http.get<any>(`${this.API_URL}/listarSeriePorNif`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <any[]>response.content,
          total: response.totalElements
        };
      });
  }

  read(filter: AuthorizationFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    return this.http.get<any>(`${this.API_URL}/resumoPorGrafica`, { params })
      .toPromise()
      .then(response => {
        return {
          body: <any[]>response.content,
          total: response.totalElements
        };
      });
  }

  listarSeriePorNifIdTipoDocumento(typeDocId: number): Promise<any> {
    return this.http.get(`${this.API_URL}/lista/${typeDocId}`)
      .toPromise();
  }

  dropdownSerieOnlineByTypeDocument(typeDocId: number): Promise<any> {
    return this.http.get(`${this.API_URL}/listaOnline/${typeDocId}`)
      .toPromise();
  }

  readAllByNif(nif: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('porNifEmissor', nif.toString());

    return this.http.get(`${this.API_URL}/resumoGeral`, { params })
      .toPromise();
  }

  dropdownByNif(nif: string) {
    return this.readAllByNif(nif)
      .then((response) => {
        return response.map((item) => ({
          label: item.tipoDocumentoNome
            + item.numSerieDocumento
            + item.anoAutorizacaoEmissao,
          value: item.idAutorizacao
        }))
      })
  }

  dropdownByNif2(): Promise<any> {
    let params = new HttpParams();

    // if (nif !== null) {
    //   params = params.append('porNifEmissor', nif.toString());
    // }

    return this.http.get(`${this.API_URL}/controlo/porEmissor/filtro`, { params })
      .toPromise();
  }

  transport(
    {
      currentEmissionDocumentId,
      requesterNif,
      applicantNif,
      newEmissionDocument,
      isTypography,
    },
    yearsToTransport?: number,
  ): Promise<EmissionDocumentResult> {
    let params = new HttpParams();
    params = params.append('nifSolicitante', requesterNif);
    params = params.append('nifRequerente', applicantNif);
    params = params.append('grafica', isTypography);

    if (yearsToTransport) params = params.append('ano', String(yearsToTransport));    

    delete newEmissionDocument.tbAutorizacaoEmissao;

    return this.http.post<EmissionDocumentResult>(`${this.API_URL}/seriePoximoAno/${currentEmissionDocumentId}`,
      newEmissionDocument, { params })
      .toPromise();
  }
}
