<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/tipografia">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TIPOGRAFIA
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          TRANSPORTAR SÉRIES PARA PRÓXIMO ANO
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <div class="alert alert-light" role="alert">
      <p>
        <span class="text-info">
          <mdb-icon fas icon="info-circle"></mdb-icon>
        </span>
        Nesta tela as tipografias podem transportar todas as séries dos seus clientes de um ano para outro.
      </p>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-header class="mdb-card-header">
        <form autocomplete="off" (ngSubmit)="read()">
          <div class="p-col-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <p-dropdown class="p-inputtext-sm" [options]="tiposDocumento" name="idTipoDocumentoEmissao"
                    [showClear]="true" [(ngModel)]="filter.idTipoDocumentoEmissao" placeholder="Tipo Documento">
                  </p-dropdown>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="serieNumber" [(ngModel)]="filter.serieNumber">
                  <label for="float-input">Série (ex.FR0000120)</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="description" [(ngModel)]="filter.description">
                  <label for="float-input">Descrição</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoDe" [(ngModel)]="filter.dataInicio"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data inicial</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <span class="p-float-label">
                  <p-calendar class="p-inputtext-sm" name="dtEmissaoDocumentoAte" [(ngModel)]="filter.dataFim"
                    dateFormat="dd/mm/yy" [locale]="locale.pt">
                  </p-calendar>
                  <label for="float-input">Data final</label>
                </span>
              </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 col-md-2">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="applicantNif" [(ngModel)]="filter.applicantNif">
                  <label for="float-input">NIF do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-4">
                <span class="p-float-label">
                  <input pInputText class="p-inputtext-sm" name="applicantName" [(ngModel)]="filter.applicantName">
                  <label for="float-input">Nome do Emitente</label>
                </span>
              </div>

              <div class="p-field p-col-12 col-md-1">
                <button type="submit" mdbBtn color="pink" size="sm" class="btn-block btn-filter">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </form>
      </mdb-card-header>

      <mdb-card-body class="mdb-card-body">
        <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="emissionDocuments"
          [loading]="loading" [responsive]="true" [paginator]="true" [rows]="filter.rows" [lazy]="true"
          [totalRecords]="total" dataKey="idPedidoEmissaoDocumento" editMode="row" (onLazyLoad)="onChangePage($event)">
          <ng-template pTemplate="caption">
            <div class="p-d-flex p-ai-center p-jc-between">
              Total de {{ total | number:'1.0-0'}} séries solicitadas.
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'10%'}">Documento</th>
              <th [style]="{width:'6%'}">Série</th>
              <th [style]="{width:'5%'}">Última Solict.</th>
              <th [style]="{width:'5%'}">Total Solicitado</th>
              <th [style]="{width:'6%'}">Data</th>
              <th [style]="{width:'12%'}">Descrição</th>
              <th [style]="{width:'20%'}">Emitente</th>
              <th [style]="{width:'20%'}">Requerente</th>
              <th [style]="{width:'2.5%'}"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
              <td>
                <span class="p-column-title">Tipo de Documento</span>
                {{
                rowData.tipoDocumento
                }}
              </td>

              <td>
                <span class="p-column-title">Série</span>
                {{
                rowData.siglaSerie +''+
                rowData.serie +''+
                rowData.ano
                }}
              </td>

              <td>
                <span class="p-column-title">Última Solicitação</span>
                {{ rowData.totalSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Total Solicitado</span>
                {{ rowData.totalJaSolicitado }}
              </td>

              <td>
                <span class="p-column-title">Data</span>
                {{rowData.data | date:'dd/MM/yyyy'}}
              </td>

              <td>
                <span class="p-column-title">Descrição</span>
                {{rowData.descricao}}
              </td>

              <td>
                <span class="p-column-title">Emitente</span>
                {{rowData.nifEmissor+' - '+rowData.nomeEmissor}}
              </td>

              <td>
                <span class="p-column-title">Requerente</span>
                {{rowData.nifRequerente+' - '+rowData.nomeRequerente}}
              </td>

              <td [style]="{padding: '1px 4px'}">
                <button type="button" mdbBtn color="info" size="sm" tooltipPosition="left"
                  pTooltip="Transportar para o próximo ano" class="btn-block" [style]="{padding: '4px 0'}"
                  (click)="showDialog(rowData)">
                  <mdb-icon fas icon="arrow-right"></mdb-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Transportar para o próximo ano" [(visible)]="display" [modal]="true" [responsive]="true"
  [style]="{width: '550px', minWidth: '200px'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
  <form #formTransport="ngForm" (ngSubmit)="transport()">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label>Série a ser transportada</label>
        <input pInputText type="text" name="serieToTransport" disabled [(ngModel)]="serieToTransport">
      </div>

      <div class="p-field p-col-12">
        <label>NIF do solicitante<strong class="red-text">*</strong></label>
        <input pInputText type="text" name="nifSolicitante" [(ngModel)]="nifSolicitante"
          placeholder="Quem fez a solicitação presencialmente" (input)="onInputNifSol($event)">

        <div *ngIf="!validNifSol" class="ui-message ui-message-error">
          NIF inválido
        </div>
      </div>

      <div class="p-field p-col-12">
        <label>Nome do solicitante<strong class="red-text">*</strong></label>
        <input pInputText type="text" name="nomeSolicitante" [(ngModel)]="nomeSolicitante" disabled>
      </div>

      <div class="p-field p-col-12">
        <label>Transportar para o ano:<strong class="red-text">*</strong></label>
        <p-dropdown [options]="yearsToTransport" name="yearToTransport" [showClear]="true" [(ngModel)]="yearToTransport"
          placeholder="Selecione o ano">
        </p-dropdown>
      </div>

      <div class="p-field p-col-12">
        <label>Quantidade de documentos</label>
        <input pInputText type="text" name="qtDocumentosSolicitado"
          [(ngModel)]="emissionDocument.qtDocumentosSolicitado">
      </div>

      <!-- <div class="p-field p-col-12 col-md-6">
        <label>Nº do primeiro documento</label>
        <input pInputText type="text" name="numInicialSequencial" [(ngModel)]="emissionDocument.numInicialSequencial">
      </div> -->

      <!--
      <div class="p-field p-col-12">
        <label>Máscara</label>
        <input pInputText type="text" name="mascara" [(ngModel)]="emissionDocument.mascara"
          placeholder="Nº dígitos da sequência dos documentos" disabled>
      </div>
      -->

      <div class="p-field p-col-12">
        <label>Descrição (Opcional)</label>
        <input pInputText type="text" name="descPedidoEmissaoDocumento"
          [(ngModel)]="emissionDocument.descPedidoEmissaoDocumento">
      </div>
    </div>

    <div class="p-col-12 text-right">
      <hr>
      <button type="button" mdbBtn size="sm" color="light" (click)="display=false">
        CANCELAR
      </button>

      <button type="submit" mdbBtn size="sm" color="green" [disabled]="!formTransport.valid || loadingTransportSerie">
        TRANSPORTAR
      </button>
    </div>

    <div class="p-col-12">
      <p-progressBar *ngIf="loadingTransportSerie" mode="indeterminate" [style]="{'height': '3px'}"></p-progressBar>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="displayTransported" [style]="{width: '60vw'}" [baseZIndex]="10000" [showHeader]="false"
  modal="true">
  <div class="p-grid">
    <div class="col-md-12 text-center  p-mt-3">
      <mdb-icon class="icon-message-lg text-success text-center" fas icon="check-circle"></mdb-icon>
      <p class="bq-title text-center p-mt-2">
        Série transportada com sucesso!
        Veja abaixo as informações da sua nova série abaixo.
      </p>
      <hr>
    </div>

    <div class="p-col-12">
      <div class="alert alert-warning" role="alert">
        <p>
          As informações de identificação do emitente na tabela abaixo devem constar obrigatoriamente nas folhas a serem
          impressas.
        </p>

        <p>
          Caso o emitente deseje alterar as mesmas informações, o mesmo deve dirigir-se à Direcção dos Impostos.
        </p>
      </div>
    </div>

    <table mdbTable borderless="true" small="true">
      <tbody>
        <tr>
          <th scope="row" [style]="{width: '30%'}">
            <strong>Número de Série</strong>
          </th>
          <td class="text-left">
            {{
            emissionDocumentResult.siglaDocAutorizacaoEmissao +''+
            emissionDocumentResult.numSerieDocumento +''+
            emissionDocumentResult.anoAutorizacaoEmissao
            }}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Número de autorização</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.numAutorizacaoEmissao}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Documentos Solicitados</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.documentoSolicitado}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Emitente</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.nomeEmitente}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>NIF</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.nifEmitente}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Telefone</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.telefoneEmitente}}
          </td>
        </tr>

        <tr>
          <th scope="row">
            <strong>Endereço</strong>
          </th>
          <td class="text-left">
            {{emissionDocumentResult.enderecoEmitente}}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-md-12">
      <hr>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="displayTransported=false">
      FECHAR
    </button>
  </ng-template>
</p-dialog>
