<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index"> INÍCIO </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADE ÚTEIS
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item routerLink="/emitter/document-online">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SISTEMA DE FACTURAÇÃO
        </mdb-breadcrumb-item>

        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          NOVA FACTURA
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <div class="alert alert-warning p-col-12" role="alert">
          <p>Todos os campos com <b class="red-text">*</b> são obrigatórios.</p>
        </div>

        <p class="p-0 m-0">Fornecedor não residente</p>
        <p-inputSwitch
          name="acceptTerms"
          [(ngModel)]="isNonResidentEmitter"
          (onChange)="setNonResident($event)"
        ></p-inputSwitch>

        <hr />

        <form autocomplete="off" [formGroup]="documentForm" (ngSubmit)="save()">
          <div class="p-fluid p-formgrid p-grid">
            <!--
            <div class="p-field p-col-12 p-md-3">
              <label>NIF do Emitente <b class="font-bold col-pink">*</b></label>
              <input pInputText name="nifEmissor" formControlName="nifEmissor">

              <app-message [control]="documentForm.get('nifEmissor')" error="required" text="Informe o NIF do Emitente">
              </app-message>

              <app-message [control]="documentForm.get('nifEmissor')" error="minlength" text="NIF inválido">
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label>Nome do Emitente <b class="font-bold col-pink">*</b></label>
              <input pInputText name="nomeEmissor" formControlName="nomeEmissor">
            </div>
          -->
            <div class="p-field p-col-12 p-md-3">
              <label
                >NIF do Adquirente <b class="font-bold col-pink"></b
              ></label>
              <input
                pInputText
                name="nifConsumidorOnline"
                formControlName="nifConsumidorOnline"
                placeholder="Obrigatório em caso de instituições"
                (input)="onInputNif($event)"
              />
            </div>

            <div class="p-field p-col-12 p-md-9">
              <label
                >Nome do Adquirente <b class="font-bold col-pink"></b
              ></label>
              <input
                pInputText
                name="nomeConsumidorOnline"
                formControlName="nomeConsumidorOnline"
              />
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Tipo Documento <b class="red-text">*</b></label>
              <p-dropdown
                [options]="typesDocument"
                name="tbTipoDocumentoEmissao"
                formControlName="tbTipoDocumentoEmissao"
                placeholder="Selecione"
                (onChange)="getSerieByTypeDocument($event.value)"
              >
              </p-dropdown>

              <app-message
                [control]="documentForm.get('tbTipoDocumentoEmissao')"
                error="required"
                text="Selecione o tipo de documento"
              ></app-message>
            </div>

            <div
              class="p-field p-col-12 p-md-3"
              formGroupName="tbAutorizacaoEmissao"
            >
              <label>Número de Série <b class="red-text">*</b></label>
              <p-dropdown
                [options]="series"
                placeholder="Selecione"
                name="idAutorizacaoEmissao"
                formControlName="idAutorizacaoEmissao"
                (onChange)="getLastNumberDocumentByAuthorization($event.value)"
              >
              </p-dropdown>

              <app-message
                [control]="
                  documentForm.get('tbAutorizacaoEmissao.idAutorizacaoEmissao')
                "
                error="required"
                text="Selecione a série do documento"
              ></app-message>
            </div>

            <br />

            <div class="p-field p-col-12 p-md-3">
              <label>Número Documento <b class="red-text">*</b></label>
              <input
                pInputText
                name="numDocumentoOnline"
                formControlName="numDocumentoOnline"
              />

              <app-message
                [control]="documentForm.get('numDocumentoOnline')"
                error="required"
                text="Informe o número  do documento"
              >
              </app-message>
            </div>

            <div class="p-field p-col-12 p-md-3">
              <label>Data de Emissão <b class="red-text">*</b></label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="dtEmissaoDocumentoOnline"
                [minDate]="ivaStartDate"
                [maxDate]="todayDate"
                formControlName="dtEmissaoDocumentoOnline"
                placeholder="dd/mm/aaaa"
              >
              </p-calendar>

              <app-message
                [control]="documentForm.get('dtEmissaoDocumentoOnline')"
                error="required"
                text="Informe a data de emissão"
              >
              </app-message>
            </div>

            <div class="p-col-12">
              <hr />
              <div formArrayName="tbItensDocumentosGeradosOnline">
                <div
                  class="p-mb-2"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.5)"
                >
                  <label
                    class="p-col p-md-1 p-text-bold p-text-left"
                    *ngIf="displayFieldForCreditNote"
                  >
                    Documento a anular<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Qtd<b class="red-text">*</b>
                  </label>
                  <label
                    class="p-col p-md-3 p-text-bold p-text-left p-m-0"
                    [style]="{ padding: '0 0 !important' }"
                  >
                    Descrição<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Valor unitário <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Subtotal<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    %{{ taxTypeToShow }} <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    {{ taxTypeToShow }} <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Código i/s <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Total <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left"></label>
                </div>

                <div *ngFor="let item of items.controls; let i = index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div
                        class="p-field p-col-12 p-md-1"
                        formGroupName="documentosOrigemOnline"
                        *ngIf="displayFieldForCreditNote"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="numDocumentoOrigemOnline"
                          (focus)="showDisplayOrigin(i)"
                          formControlName="numDocumentoOrigemOnline"
                          placeholder="Documento origem"
                        />
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber
                          mode="decimal"
                          class="p-inputtext-sm"
                          [max]="1000000000"
                          [minFractionDigits]="2"
                          name="quantItens"
                          formControlName="quantItens"
                          placeholder="Quantidade"
                          (onBlur)="calcItemTax(i)"
                          (focus)="$event.target.select()"
                        >
                        </p-inputNumber>

                        <app-message
                          [control]="items.at(i).get('quantItens')"
                          error="required"
                          text="Informe a quantidade"
                        >
                        </app-message>

                        <app-message
                          [control]="items.at(i).get('quantItens')"
                          error="min"
                          text="A quantidade deve ser maior ou igual a 0.25"
                        >
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-3">
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descItens"
                          formControlName="descItens"
                          maxlength="100"
                          placeholder="Descrição (máximo 100 caracteres)"
                        />

                        <app-message
                          [control]="items.at(i).get('descItens')"
                          error="required"
                          text="Informe a descrição do produto ou serviço"
                        >
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber
                          mode="decimal"
                          [minFractionDigits]="6"
                          name="valorUnitItens"
                          formControlName="valorUnitItens"
                          class="p-inputtext-sm"
                          placeholder="Valor unitário"
                          (onBlur)="calcItemTax(i)"
                          (focus)="$event.target.select()"
                        >
                        </p-inputNumber>

                        <app-message
                          [control]="items.at(i).get('valorUnitItens')"
                          error="required"
                          text="Informe o valor unitário"
                        >
                        </app-message>

                        <app-message
                          [control]="items.at(i).get('valorUnitItens')"
                          error="min"
                          text="Valor unitário deve ser maior ou igual 1,00"
                        >
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber
                          mode="decimal"
                          [minFractionDigits]="6"
                          name="valorBaseTributavelItens"
                          formControlName="valorBaseTributavelItens"
                          class="p-inputtext-sm"
                          placeholder="Subtotal"
                        >
                        </p-inputNumber>

                        <app-message
                          [control]="
                            items.at(i).get('valorBaseTributavelItens')
                          "
                          error="required"
                          text="Informe o montante"
                        >
                        </app-message>
                      </div>

                      <div
                        class="p-field p-col-12 p-md-1"
                        formGroupName="tbTaxaAplicavel"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          name="descTaxaAplicavel"
                          [attr.disabled]="
                            !showReceiptFields() ? 'disabled' : null
                          "
                          formControlName="descTaxaAplicavel"
                          [placeholder]="'Taxa (' + taxTypeToShow + ')'"
                          (focus)="showTaxRate(i)"
                        />

                        <app-message
                          [control]="
                            items.at(i).get('tbTaxaAplicavel.descTaxaAplicavel')
                          "
                          error="required"
                          [text]="'Informe a taxa ' + taxTypeToShow"
                        >
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="6"
                          name="valorImposto"
                          formControlName="valorImposto"
                          placeholder="Imposto"
                        >
                        </p-inputNumber>
                      </div>

                      <div
                        class="p-field p-col-12 p-md-1"
                        formGroupName="tbMotivoIsencao"
                      >
                        <input
                          pInputText
                          class="p-inputtext-sm"
                          [attr.disabled]="
                            !showReceiptFields() ? 'disabled' : null
                          "
                          name="codigoMotivoIsencao"
                          formControlName="codigoMotivoIsencao"
                          placeholder="Código de isenção/sujeição"
                          (focus)="showDisplayExemption(i)"
                        />

                        <app-message
                          [control]="
                            items
                              .at(i)
                              .get('tbMotivoIsencao.codigoMotivoIsencao')
                          "
                          error="required"
                          text="Informe código de isenção ou sujeição"
                        >
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber
                          class="p-inputtext-sm"
                          mode="decimal"
                          [minFractionDigits]="6"
                          name="totalLinhaAposImpostoItens"
                          formControlName="totalLinhaAposImpostoItens"
                          placeholder="Total do item"
                        >
                        </p-inputNumber>
                      </div>

                      <div class="p-col-12 p-md-1">
                        <button
                          type="button"
                          mdbBtn
                          color="danger"
                          size="sm"
                          pTooltip="Remover item"
                          tooltipPosition="left"
                          (click)="removeItem(i)"
                        >
                          <mdb-icon fas icon="trash"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                mdbBtn
                color="info"
                size="sm"
                pTooltip="Adicionar linha"
                tooltipPosition="right"
                (click)="addItem()"
                [style]="{ margin: '5px 0 15px 0' }"
              >
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>
              <hr />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Montante <b class="red-text">*</b></label>
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="6"
                name="totalValorBaseTributavelOnline"
                formControlName="totalValorBaseTributavelOnline"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>{{ taxTypeToShow }} <b class="red-text">*</b></label>
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="6"
                name="totalImpostoOnline"
                formControlName="totalImpostoOnline"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Total <b class="red-text">*</b></label>
              <p-inputNumber
                mode="decimal"
                [minFractionDigits]="6"
                name="totalValorOnline"
                formControlName="totalValorOnline"
              >
              </p-inputNumber>
            </div>

            <div class="p-field p-col-12">
              <label>Observações</label>
              <p-editor
                name="observacaoDocumentoGeradoOnline"
                formControlName="observacaoDocumentoGeradoOnline"
                [style]="{ height: '120px' }"
              >
                <ng-template pTemplate="header">
                  <span class="ql-format-group">
                    <button
                      type="button"
                      class="ql-bold"
                      aria-label="Bold"
                    ></button>
                    <button
                      type="button"
                      class="ql-italic"
                      aria-label="Italic"
                    ></button>
                    <button
                      type="button"
                      class="ql-underline"
                      aria-label="Underline"
                    ></button>
                  </span>
                </ng-template>
              </p-editor>
            </div>

            <div class="p-col-12 text-right">
              <hr />
              <button
                type="button"
                mdbBtn
                color="light"
                mdbWavesEffect
                routerLink="/emitter/document-online"
              >
                VOLTAR
              </button>

              <button
                type="button"
                mdbBtn
                color="primary"
                mdbWavesEffect
                (click)="save()"
              >
                SALVAR
              </button>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog
  header="Selecione a taxa"
  modal="true"
  [(visible)]="displayTaxRate"
  [style]="{ width: '50vw', height: 'auto' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <p-table
    styleClass="p-datatable-striped p-datatable-responsive-demo"
    [value]="taxRates"
    selectionMode="single"
    [(selection)]="selectedTaxRate"
    (onRowSelect)="onTaxRateSelect($event)"
    [rows]="10"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '60%' }">Imposto</th>
        <th [style]="{ width: '20%' }">Sigla</th>
        <th [style]="{ width: '20%' }">Taxa</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.descricaoTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Imposto</span>
          {{ rowData.tbTipoImposto.siglaTipoImposto }}
        </td>
        <td>
          <span class="p-column-title">Taxa</span>
          {{ rowData.descTaxaAplicavel }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayTaxRate = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o código de isenção/sujeição"
  modal="true"
  [(visible)]="displayExemption"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '80vw' }"
  [baseZIndex]="10000"
>
  <p-table
    styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
    [value]="exemptionReasons"
    selectionMode="single"
    [(selection)]="selectedExemptionReason"
    (onRowSelect)="onExemptionSelect($event)"
    [rows]="5"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{ width: '10%' }">Código</th>
        <th [style]="{ width: '60%' }">Descrição</th>
        <!-- <th [style]="{ width: '20%' }">Artigo</th> -->
        <th [style]="{ width: '10%' }">Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{ rowData.codigoMotivoIsencao }}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{ rowData.desMotivoIsencao }}
        </td>
        <!-- <td>
          <span class="p-column-title">Artigo</span>
          {{ rowData.artigo }}
        </td> -->
        <td>
          <span class="p-column-title">Situação</span>
          {{ getCodeSituationInTable(rowData) }}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayExemption = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog
  header="Detalhes do Documento"
  [(visible)]="display"
  [responsive]="true"
  [style]="{ width: '50vw' }"
  [maximizable]="true"
  [baseZIndex]="10000"
>
  <kendo-pdf-export #pdf paperSize="A4" margin="0.4cm">
    <div class="invoice-box">
      <table cellpadding="0" cellspacing="0">
        <tr class="top">
          <td colspan="2">
            <table class="tb-two-rows">
              <tr>
                <td class="title">
                  <span>
                    <strong>
                      {{ documentForm.get("nomeEmissor").value | titlecase }}
                    </strong>
                  </span>
                  <br />
                  <span>
                    <strong>NIF: </strong>
                    {{ documentForm.get("nifEmissor").value }}
                  </span>
                  <br />
                  <span>
                    <strong> Endereço: </strong>
                    {{ documentForm.get("enderecoEmissor").value | titlecase }}
                  </span>
                  <br />
                  <span>
                    <strong> Telefone: </strong>
                    {{ documentForm.get("telefoneEmissor").value | titlecase }}
                  </span>
                  <br />
                </td>

                <td class="number">
                  <span
                    ><strong>
                      {{
                        documentForm.get("tbTipoDocumentoEmissao").value
                          ? documentForm.get("tbTipoDocumentoEmissao").value
                              .descTipoDocumentoEmissao
                          : ""
                      }}
                    </strong></span
                  >
                  <br />
                  <span>
                    <strong>
                      Número:
                      {{
                        documentForm.get("tbAutorizacaoEmissao").value
                          .siglaDocAutorizacaoEmissao +
                          documentForm.get("tbAutorizacaoEmissao").value
                            .numSerieDocumento +
                          documentForm.get("tbAutorizacaoEmissao").value
                            .anoAutorizacaoEmissao +
                          documentForm.get("numDocumentoOnline").value
                      }}
                    </strong> </span
                  ><br />
                  <span>
                    <strong>Data: </strong>
                    {{
                      documentForm.get("dtEmissaoDocumentoOnline").value
                        | date : "dd/MM/yyyy"
                    }}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr class="consumer">
          <td colspan="2">
            <table class="tb-two-rows">
              <tr>
                <td>
                  <strong>Adquirente: </strong> <br />
                  {{
                    documentForm.get("nomeConsumidorOnline").value | titlecase
                  }}
                </td>
                <td>
                  <strong>NIF: </strong> <br />
                  {{ documentForm.get("nifConsumidorOnline").value }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <div class="invoice-items">
        <table class="tb-items table table-sm table-striped">
          <thead>
            <tr>
              <th>Qtd</th>
              <th>Descrição</th>
              <th>V. Unit.</th>
              <th>Sub Total</th>
              <th>{{ taxTypeToShow }}</th>
              <th>V.Total</th>
            </tr>
          </thead>

          <tbody>
            <tr class="item" *ngFor="let item of items.controls; let i = index">
              <td>{{ item.get("quantItens").value }}</td>
              <td [style]="{ textAlign: 'left' }">
                {{ item.get("descItens").value }}
              </td>
              <td>{{ item.get("valorUnitItens").value }}</td>
              <td>{{ item.get("valorBaseTributavelItens").value }}</td>
              <td>
                {{ item.get("valorImposto").value }}
                <span
                  *ngIf="
                    item.get('tbMotivoIsencao.codigoMotivoIsencao').value ===
                    '153'
                  "
                >
                  (ALC)</span
                >
              </td>
              <td>{{ item.get("totalLinhaAposImpostoItens").value }}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="tb-items-total tb-two-rows"
          cellpadding="0"
          cellspacing="0"
        >
          <tr>
            <td></td>
            <td>
              Subtotal:
              <strong>
                {{ documentForm.get("totalValorBaseTributavelOnline").value }}
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              Total {{ taxTypeToShow }}:
              <strong>
                {{ documentForm.get("totalImpostoOnline").value }}
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              Total:
              <strong>
                {{ documentForm.get("totalValorOnline").value }}
              </strong>
            </td>
          </tr>
        </table>
      </div>

      <div class="invoice-obs">
        <table cellpadding="0" cellspacing="0">
          <tr>
            <td>
              <div
                [innerHTML]="
                  documentForm.get('observacaoDocumentoGeradoOnline').value
                "
              ></div>
            </td>
          </tr>
        </table>
      </div>

      <div class="invoice-footer">
        <i *ngIf="isAlc">IVA Autoliquidado pelo Consumidor (ALC)</i>
        <br />
        <span
          >Número de certificado:
          {{ documentForm.get("numeroCertificacao").value }}
        </span>
        <br />
        <span>
          Número de Autorização:
          {{
            documentForm.get("tbAutorizacaoEmissao").value
              ? documentForm.get("tbAutorizacaoEmissao").value
                  .numAutorizacaoEmissao
              : ""
          }}
        </span>
      </div>
    </div>
  </kendo-pdf-export>

  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="display = false"
    >
      CANCELAR
    </button>

    <button
      type="button"
      mdbBtn
      color="success"
      size="sm"
      mdbWavesEffect
      (click)="confirm()"
      [disabled]="documentForm.invalid || loading"
    >
      CONFIRMAR
    </button>
  </div>
  <div class="p-col-12">
    <p-progressBar
      *ngIf="loading"
      mode="indeterminate"
      [style]="{ height: '3px' }"
    >
    </p-progressBar>
  </div>
</p-dialog>

<p-dialog
  header="Selecione o documento de origem"
  [(visible)]="displayOrigin"
  [style]="{ width: '70vw' }"
  [baseZIndex]="10000"
  [contentStyle]="{ overflow: 'visible' }"
>
  <form autocomplete="off" (ngSubmit)="readOriginsByEmitter()">
    <div class="p-col-12">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-5">
          <span class="p-float-label">
            <input
              pInputText
              class="p-inputtext-sm"
              name="numDocumento"
              [(ngModel)]="filterOrigin.numDocumento"
            />
            <label for="float-input">Número Documento</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-2">
          <button
            type="submit"
            mdbBtn
            color="pink"
            size="sm"
            class="btn-block btn-filter"
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  </form>

  <p-table
    styleClass="p-datatable-striped p-datatable-responsive-demo"
    [value]="documentsOrigin"
    [loading]="loadingOrigin"
    selectionMode="single"
    [(selection)]="selectedOrigin"
    (onRowSelect)="onOriginSelect($event)"
    [paginator]="true"
    [rows]="filterOrigin.minRows"
    [totalRecords]="totalDocumentsOrigin"
    [lazy]="true"
    (onLazyLoad)="onChangePage($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Número Documento</th>
        <th>Data</th>
        <th>Montante</th>
        <th>{{ taxTypeToShow }}</th>
        <th>Total</th>
        <th>Montante a anular</th>
        <th [style]="{ width: '3em' }"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-origin>
      <tr>
        <td>{{ origin.numDocumento }}</td>
        <td>{{ origin.dtEmissaoDocumento | date : "dd/MM/yyyy" }}</td>
        <td>{{ origin.totalValorBaseTributavel }}</td>
        <td>{{ origin.totalImposto }}</td>
        <td>{{ origin.totalValor }}</td>
        <td>{{ origin.valorDisponivelAnular }}</td>
        <td [style]="{ padding: '1px 4px' }">
          <button
            type="button"
            mdbBtn
            color="info"
            size="sm"
            block="true"
            pTooltip="Selecionar documento"
            tooltipPosition="left"
            [style]="{ padding: '4px 0' }"
            (click)="onOriginSelect(origin)"
          >
            <mdb-icon fas icon="hand-pointer"></mdb-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="p-col-12 text-right">
    <hr />
    <button
      type="button"
      mdbBtn
      color="light"
      size="sm"
      mdbWavesEffect
      (click)="displayOrigin = false"
    >
      FECHAR
    </button>
  </div>
</p-dialog>
<!-- <app-modal
  *ngIf="showFrameworkModal"
  [genericError]="isGenericTaxIvaRegimeError"
></app-modal> -->
