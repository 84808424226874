<div class="p-grid">
  <div class="p-col-12">
    <div class="light-font bc-icons-2">
      <mdb-breadcrumb customClass="bg-light-teal white-text">
        <mdb-breadcrumb-item routerLink="/index">
          INÍCIO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/util">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          FUNCIONALIDADES ÚTEIS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/document-online">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          SISTEMA DE FACTURAÇÃO
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item routerLink="/emitter/document-online/read">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          VERIFICAR DOCUMENTOS
        </mdb-breadcrumb-item>
        <mdb-breadcrumb-item customClass="white-text">
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon>
          EDITAR
        </mdb-breadcrumb-item>
      </mdb-breadcrumb>
    </div>
  </div>

  <div class="p-col-12">
    <mdb-card>
      <mdb-card-body>
        <form autocomplete="off" (ngSubmit)="update()" [formGroup]="documentOnline">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12" formGroupName="tbContribuinteConsumidor">
              <div class="p-fluid p-grid" formGroupName="tbPessoa">
                <div class="p-field p-col-12 p-md-4">
                  <label class="p-text-bold">NIF do Adquirente<b class="red-text">*</b></label>
                  <input pInputText name="nifPessoa" formControlName="nifPessoa">

                  <app-message [control]="documentOnline.get('tbContribuinteConsumidor.tbPessoa.nifPessoa')"
                    error="required" text="Este campo é obrigatório.">
                  </app-message>

                  <app-message [control]="documentOnline.get('tbContribuinteConsumidor.tbPessoa.nifPessoa')"
                    error="minlength" text="O nº de dígitos não deve ser menor que 9.">
                  </app-message>

                  <app-message [control]="documentOnline.get('tbContribuinteConsumidor.tbPessoa.nifPessoa')"
                    error="maxlength" text="O nº de dígitos não deve ser maior que 9.">
                  </app-message>
                </div>

                <div class="p-field p-col-12 p-md-8">
                  <label class="p-text-bold">Nome do Adquirente</label>
                  <input pInputText name="nomePessoa" formControlName="nomePessoa">
                </div>
              </div>
            </div>

            <div class="p-field p-col-12 p-md-4" formGroupName="tbAutorizacaoEmissao">
              <label class="p-text-bold">Tipo de Documento</label>
              <input pInputText type="text" name="descTipoDocumento" formControlName="siglaDocAutorizacaoEmissao">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Número da Documento</label>
              <input pInputText name="numDocumentoOnline" formControlName="numDocumentoOnline">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Data de Emissão<b class="red-text">*</b></label>
              <p-calendar name="dtEmissaoDocumentoOnline" formControlName="dtEmissaoDocumentoOnline"
                dateFormat="dd/mm/yy">
              </p-calendar>

              <!-- <app-message [control]="dtEmissaoDocumento" error="required" text="Informe a data de emissão">
              </app-message> -->
            </div>

            <div class="p-col-12">
              <hr>

              <div formArrayName="tbItensDocumentosGeradosOnline">
                <div class="p-grid p-mt-2">
                  <!-- <label class="p-col-fixed p-text-bold" [ngStyle]="{width:'250px'}" *ngIf="displayFieldForCreditNote">
                    Documento a anular<b class="red-text">*</b>
                  </label> -->
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Qtd<b class="red-text">*</b>
                  </label>
                  <label class="p-col-fixed p-text-bold" [ngStyle]="{width:'450px'}">
                    Descrição (opcional)
                  </label>
                  <label class="p-col p-md-1 p-text-bold p-text-left">
                    Valor unitário <b class="red-text">*</b>
                  </label>
                  <label class="p-col p-text-bold">
                    Subtotal<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-text-bold">
                    Taxa ({{taxTypeToShow}})<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-text-bold">
                    Código i/s<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-text-bold">
                    {{taxTypeToShow}}<b class="red-text">*</b>
                  </label>
                  <label class="p-col p-text-bold">
                    Total<b class="red-text">*</b>
                  </label>
                  <label class="p-col-fixed" [ngStyle]="{width:'100px'}">
                  </label>
                </div>

                <div *ngFor="let item of items.controls; let i=index">
                  <div [formGroupName]="i">
                    <div class="p-grid">
                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber mode="decimal" class="p-inputtext-sm" [max]="100000" [minFractionDigits]="2"
                          name="quantItens" formControlName="quantItens" placeholder="Quantidade"
                          (onBlur)="calcItemTax(i)" (focus)="$event.target.select()">
                        </p-inputNumber>

                        <app-message [control]="items.at(i).get('quantItens')" error="required"
                          text="Informe a quantidade">
                        </app-message>

                        <app-message [control]="items.at(i).get('quantItens')" error="min"
                          text="A quantidade deve ser maior ou igual a 0.25">
                        </app-message>
                      </div>

                      <div class="p-field p-col-fixed" [ngStyle]="{width:'450px'}">
                        <input pInputText class="p-inputtext-sm" name="descItens" maxlength="100"
                          placeholder="Descrição (máximo 100 caracteres)" formControlName="descItens">

                        <app-message [control]="items.at(i).get('descItens')" error="required"
                          text="Informe a descrição do produto ou serviço">
                        </app-message>
                      </div>

                      <div class="p-field p-col-12 p-md-1">
                        <p-inputNumber mode="decimal" [minFractionDigits]="6" name="valorUnitItens"
                          formControlName="valorUnitItens" class="p-inputtext-sm" placeholder="Valor unitário"
                          (onBlur)="calcItemTax(i)" (focus)="$event.target.select()">
                        </p-inputNumber>

                        <app-message [control]="items.at(i).get('valorUnitItens')" error="required"
                          text="Informe o valor unitário">
                        </app-message>

                        <app-message [control]="items.at(i).get('valorUnitItens')" error="min"
                          text="Valor unitário deve ser maior ou igual 1,00">
                        </app-message>
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber mode="decimal" [minFractionDigits]="2" name="valorBaseTributavelItens"
                          class="p-inputtext-sm" placeholder="Montante do item"
                          formControlName="valorBaseTributavelItens" (onBlur)="calcItemTax(i)">
                        </p-inputNumber>

                        <app-message [control]="items.at(i).get('valorBaseTributavelItens')" error="required"
                          text="Informe o montante">
                        </app-message>
                      </div>

                      <div class="p-field p-col" formGroupName="tbTaxaAplicavel">
                        <input pInputText class="p-inputtext-sm" name="tbTaxaAplicavel" placeholder="Taxa"
                          formControlName="descTaxaAplicavel" (focus)="showTaxRate(i)" [attr.disabled]="!showReceiptFields() ? 'disabled' : null">
                      </div>

                      <div class="p-field p-col" formGroupName="tbMotivoIsencao">
                        <input pInputText class="p-inputtext-sm" name="tbMotivoIsencao"
                          formControlName="codigoMotivoIsencao" placeholder="Código de isenção/sujeição"
                          (focus)="showDisplayExemption(i)" [attr.disabled]="!showReceiptFields() ? 'disabled' : null">
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2" name="valorImposto"
                          formControlName="valorImposto" placeholder="Imposto">
                        </p-inputNumber>
                      </div>

                      <div class="p-field p-col">
                        <p-inputNumber class="p-inputtext-sm" mode="decimal" [minFractionDigits]="2"
                          name="totalLinhaAposImpostoItens" formControlName="totalLinhaAposImpostoItens"
                          placeholder="Total do item">
                        </p-inputNumber>
                      </div>

                      <div class="p-col-fixed" [ngStyle]="{width:'100px'}">
                        <button type="button" mdbBtn color="danger" size="sm" pTooltip="Remover item"
                          tooltipPosition="left" (click)="removeItem(i)">
                          <mdb-icon fas icon="trash"></mdb-icon>
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <button type="button" mdbBtn color="info" size="sm" pTooltip="Adicionar linha" tooltipPosition="right"
                (click)="addItem()" [style]="{margin: '5px 0 15px 0'}">
                <mdb-icon fas icon="plus"></mdb-icon>
              </button>

              <hr>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Base Tributável</label>
              <input pInputText name="totalValorBaseTributavelOnline" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                formControlName="totalValorBaseTributavelOnline">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Imposto</label>
              <input pInputText name="totalImpostoOnline" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" formControlName="totalImpostoOnline">
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label class="p-text-bold">Valor Total</label>
              <input pInputText name="totalValorOnline" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' }" formControlName="totalValorOnline">
            </div>

            <div class="p-col-12 p-p-0 text-right">
              <hr>
              <button type="button" mdbBtn color="light" mdbWavesEffect
                [routerLink]="['/emitter/document-online/read', documentOnline.get('idDocumentoGeradoOnline')]">
                VOLTAR
              </button>

              <!-- <button type="submit" mdbBtn color="primary" mdbWavesEffect
                [disabled]="documentOnline.invalid || loading">
                SALVAR ALTERAÇÕES
              </button> -->
            </div>

            <div class="p-col-12">
              <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '3px'}">
              </p-progressBar>
            </div>
          </div>
        </form>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

<p-dialog header="Selecione a taxa" modal="true" [(visible)]="displayTaxRate" [style]="{width: '50vw', height: 'auto'}"
  [baseZIndex]="10000" [contentStyle]="{'overflow':'visible'}">

  <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="taxRates"
    selectionMode="single" [(selection)]="selectedTaxRate" (onRowSelect)="onTaxRateSelect($event)" [rows]="10">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width: '60%'}">Imposto</th>
        <th [style]="{width: '20%'}">Sigla</th>
        <th [style]="{width: '20%'}">Taxa</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Imposto</span>
          {{rowData.tbTipoImposto.descricaoTipoImposto}}
        </td>
        <td>
          <span class="p-column-title">Imposto</span>
          {{rowData.tbTipoImposto.siglaTipoImposto}}
        </td>
        <td>
          <span class="p-column-title">Taxa</span>
          {{rowData.descTaxaAplicavel}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr>
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="displayTaxRate=false">
      FECHAR
    </button>
  </div>
</p-dialog>

<p-dialog header="Selecione o código de isenção/sujeição" modal="true" [(visible)]="displayExemption"
  [style]="{width: '50vw', height: 'auto'}" [baseZIndex]="10000" [contentStyle]="{'overflow':'visible'}">

  <p-table styleClass="p-datatable-sm p-datatable-striped p-datatable-responsive-demo" [value]="exemptionReasons"
    selectionMode="single" [(selection)]="selectedExemptionReason" (onRowSelect)="onExemptionSelect($event)" [rows]="5">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width: '10%'}">Código</th>
        <th [style]="{width: '45%'}">Descrição</th>
        <th [style]="{width: '35%'}">Artigo</th>
        <th [style]="{width: '10%'}">Situação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td>
          <span class="p-column-title">Código</span>
          {{rowData.codigoMotivoIsencao}}
        </td>
        <td>
          <span class="p-column-title">Descrição</span>
          {{rowData.desMotivoIsencao}}
        </td>
        <td>
          <span class="p-column-title">Artigo</span>
          {{rowData.artigo}}
        </td>
        <td> <span class="p-column-title">Situação</span>
          {{rowData.isento ? 'Isenção' : 'Sujeição'}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 text-right">
    <hr>
    <button type="button" mdbBtn color="light" size="sm" mdbWavesEffect (click)="displayExemption=false">
      FECHAR
    </button>
  </div>

</p-dialog>
<!-- <app-modal *ngIf="showFrameworkModal" [genericError]="isGenericTaxIvaRegimeError"></app-modal> -->