import { Component, OnInit } from '@angular/core';
import {
  EmissionDocument,
  EmissionDocumentResult,
  EmissionDocumentView,
} from 'src/app/core/models/emission-document';
import {
  EmissionDocumentEmitterFilter,
  EmissionDocumentEmitterService,
} from '../emission-document-emitter.service';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Locale } from 'src/app/configs/models/locale';
import { LazyLoadEvent } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { BlockedTaxpayerInfo } from 'src/app/core/models/taxpayer';

@Component({
  selector: 'app-transport-serie-manually',
  templateUrl: './transport-serie-manually.component.html',
  styleUrls: ['./transport-serie-manually.component.scss'],
})
export class TransportSerieManuallyComponent implements OnInit {
  loading: boolean = true;
  loadingTransport: boolean = false;
  display: boolean = false;
  displayResult: boolean = false;

  locale = new Locale();
  filter = new EmissionDocumentEmitterFilter();
  emissionDocument = new EmissionDocument();
  emissionDocumentView = new EmissionDocumentView();

  emissionDocumentResult = new EmissionDocumentResult();

  total = 0;

  typesDocument = [];
  emissionDocuments = [];

  nifSolicitante: string;
  nomeSolicitante: string;
  applicantNif: string;

  serieToTransport: string;
  yearsToTransport = [];
  yearToTransport: string;

  blockedTaxpayerInfo: BlockedTaxpayerInfo;
  displayBlockedSerieMessage: boolean = false;

  constructor(
    private service: EmissionDocumentEmitterService,
    private authorizationService: AuthorizationService,
    private tipoDocumentoService: TypeDocumentService,
    private errorHandler: ErrorHandlerService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    // this.applicantNif = this.auth.jwtPayload.nif;
    // this.nifSolicitante = this.auth.jwtPayload.nif;
    this.readTypeDocument();
    this.getYearsToTransport();
  }

  getYearsToTransport(): void {
    this.service
      .getYearsToTransport()
      .then((results) => {
        const years = results.map((result: any) => ({
          value: result,
          label: result.toString(),
        }));

        this.yearsToTransport = years;
      })
      .catch((err) => this.errorHandler.handle(err));
  }

  readTypeDocument() {
    return this.tipoDocumentoService
      .read()
      .then((response) => {
        this.typesDocument = response.map((t) => ({
          name: t.descTipoDocumentoEmissao,
          code: t.idTipoDocumentoEmissao,
        }));
      })
      .catch((erro) => this.errorHandler.handle(erro));
  }

  read(pagina = 0) {
    this.loading = true;
    this.filter.page = pagina;
    this.service
      .readNotTransported(this.filter)
      .then((response) => {
        this.total = response.total;
        this.emissionDocuments = response.body;
      })
      .catch((erro) => this.errorHandler.handle(erro))
      .finally(() => (this.loading = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.read(pagina);
  }

  transport(form: NgForm) {
    this.loadingTransport = true;

    const newEmissionDocument = new EmissionDocument();

    newEmissionDocument.qtDocumentosSolicitado =
      this.emissionDocument.qtDocumentosSolicitado;

    newEmissionDocument.mascara = this.emissionDocument.mascara;

    newEmissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocument.descPedidoEmissaoDocumento;

    const data = {
      currentEmissionDocumentId:
        this.emissionDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao,
      requesterNif: this.nifSolicitante,
      applicantNif: this.applicantNif,
      newEmissionDocument: newEmissionDocument,
      isTypography: false,
    };

    const yearToTransport = Number(this.yearToTransport);
    
    this.authorizationService
      .transport(data, yearToTransport)
      .then((response) => {
        this.emissionDocumentResult = response;
        this.read();
        this.display = false;
        this.displayResult = true;
        form.reset();
      })
      .catch((error) => {
        if (error.status === 406) {
          this.blockedTaxpayerInfo = error.error;
          this.displayBlockedSerieMessage = true;
          return;
        }

        this.errorHandler.handle(error);
      })
      .finally(() => (this.loadingTransport = false));
  }

  showDialog(ed: EmissionDocumentView) {
    this.emissionDocumentView = ed;

    this.serieToTransport = `${ed.siglaSerie}${ed.serie}${ed.ano}`;

    this.emissionDocument.idPedidoEmissaoDocumento =
      this.emissionDocumentView.idPedidoEmissaoDocumento;

    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;

    this.emissionDocument.mascara = this.emissionDocumentView.mascara;

    this.emissionDocument.numInicialSequencial =
      this.emissionDocumentView.numInicialSequencial;

    this.emissionDocument.qtDocumentosSolicitado =
      this.emissionDocumentView.totalSolicitado;

    this.emissionDocument.descPedidoEmissaoDocumento =
      this.emissionDocumentView.descricao;

    this.emissionDocument.tbAutorizacaoEmissao.idAutorizacaoEmissao =
      this.emissionDocumentView.idAutorizacaoEmissao;

    this.emissionDocument.tbTipoDocumentoEmissao.idTipoDocumentoEmissao =
      this.emissionDocumentView.idTipoDocumento;

    this.display = true;
  }
}
