import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { AuthorizationService } from 'src/app/authorization/authorization.service';
import { CertificationService } from 'src/app/certification/certification.service';
import { Mathematic } from 'src/app/configs/models/math';
import { Document } from 'src/app/core/models/document';
import { DocumentItemOrigin } from 'src/app/core/models/document-item-origin';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';
import { TypeDocumentService } from 'src/app/type-document/type-document.service';
import { encrypt } from '../../configs/encryption';
import { Locale } from './../../configs/models/locale';
import { ErrorHandlerService } from './../../core/error-handler.service';
import {
  DocumentOnline,
  DocumentOnlineForm,
  DocumentOnlineView,
} from './../../core/models/document-online';
import { DocumentOnlineItemForm } from './../../core/models/document-online-item';
import { ExemptionReason } from './../../core/models/exemption-reason';
import { TaxRate } from './../../core/models/tax-rate';
import { TypeDocument } from './../../core/models/type-document';
import {
  DocumentEmitterFilter,
  DocumentEmitterService,
} from './../../document-emitter/document-emitter.service';
import { ExemptionReasonService } from './../../exemption-reason/exemption-reason.service';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import {
  DocumentOnlineEmitterFilter,
  DocumentOnlineEmitterService,
} from './../document-online-emitter.service';
import { ViewDialogComponent } from './../view-dialog/view-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { buildCurrentDate } from 'src/app/shared/date';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  @ViewChild(ViewDialogComponent) ViewDialogComponent: any;

  loading: boolean = false;
  loadingOrigin: boolean = true;

  locale = new Locale();
  document = new DocumentOnline();
  documentForm = new DocumentOnlineForm(this.fb).builder();
  documentItemForm = new DocumentOnlineItemForm(this.fb);
  filter = new DocumentOnlineEmitterFilter();

  typesDocument: TypeDocument[];
  taxRates: TaxRate[];
  exemptionReasons: ExemptionReason[];
  series = [];

  selectedExemptionReason: ExemptionReason;
  selectedTaxRate: TaxRate;

  display: boolean = false;
  displayExemption: boolean = false;
  displayTaxRate: boolean = false;
  validNif: boolean = true;

  itemIndex: number = 0;

  displayFieldForCreditNote: boolean = false;
  displayOrigin: boolean = false;
  totalDocumentsOrigin = 0;
  filterOrigin = new DocumentEmitterFilter();
  documentsOrigin: DocumentOnlineView[];
  selectedOrigin: DocumentItemOrigin;

  ivaStartDate: Date = new Date(2023, 6, 1);
  todayDate: Date = new Date();
  userNif: string;
  showFrameworkModal = false;
  isGenericTaxIvaRegimeError = false;
  currentDocumentDate = buildCurrentDate(new Date());
  taxTypeToShow = 'IVA';
  iscTax = false;

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private taxRateService: TaxRateService,
    private documentEmitterService: DocumentEmitterService,
    private router: Router,
    private taxPayerService: TaxpayerService,
    private errorHandler: ErrorHandlerService,
    private typeDocumentService: TypeDocumentService,
    private authorizationService: AuthorizationService,
    private exemptionReasonService: ExemptionReasonService,
    private documentOnlineEmitterService: DocumentOnlineEmitterService,
    private certificationService: CertificationService,
  ) {}

  ngOnInit(): void {
    this.addItem();

    this.typeDocumentService
      .dropdownOnline()
      .then((response) => (this.typesDocument = response));

    this.getActiveTaxRates();
  }

  clearTaxesRegime(): void {
    this.selectedTaxRate = null;
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbTaxaAplicavel.descTaxaAplicavel').reset();
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  clearTaxesRegimeCode(): void {
    this.selectedExemptionReason = null;
    for (let i = 0; i < this.items.length; i++) {
      this.items.at(i).get('tbMotivoIsencao.codigoMotivoIsencao').reset();
    }
  }

  showReceiptFields(): boolean {
    return this.documentForm.get('tbTipoDocumentoEmissao').valid &&
      this.documentForm.get('tbAutorizacaoEmissao.idAutorizacaoEmissao').valid &&
      this.documentForm.get('dtEmissaoDocumentoOnline').valid;
  }

  get items(): UntypedFormArray {
    return this.documentForm.get(
      'tbItensDocumentosGeradosOnline'
    ) as UntypedFormArray;
  }

  get emissionDocumentType(): FormControl {
    return this.documentForm.get('tbTipoDocumentoEmissao') as FormControl;
  }

  addItem() {
    this.items.push(this.documentItemForm.builder());
  }

  async removeItem(i: number) {
    if (this.items.controls.length > 1) {
      await this.items.removeAt(i);
      this.calcTax();
    }
  }

  getByTaxpayerId(idContribuinte: number) {
    this.certificationService
      .getByTaxpayerId(idContribuinte)
      .then((response) => {
        this.documentForm.patchValue({
          nifEmissor: response.tbContribuinte.nifContribuinte,
          nomeEmissor: response.tbContribuinte.tbPessoa.nomePessoa,
          telefoneEmissor: response.tbContribuinte.tbPessoa.telefonePessoa,
          enderecoEmissor: response.tbContribuinte.tbPessoa.enderecoPessoa,
          numeroCertificacao: response.numeroCertificacao,
        });
      })
      .catch((erro) => {
        if (erro.status == 404) {
          this.router.navigate([
            '/funcionalidades-uteis/emissoao-factura/novo/nao-certificado',
          ]);
        }
      });
  }

  onInputNif(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.documentForm
            .get('nomeConsumidorOnline')
            .patchValue(response.contriNome);
          this.validNif = true;
        })
        .catch(() => {
          this.validNif = false;
          this.documentForm.get('nomeConsumidorOnline').patchValue(null);
        });
    } else {
      this.validNif = false;
      this.documentForm.get('nomeConsumidorOnline').patchValue(null);
    }
  }

  async getSerieByTypeDocument(typeDocument) {
    this.displayFormCreditNote(typeDocument);

    const response =
      await this.authorizationService.dropdownSerieOnlineByTypeDocument(
        typeDocument.idTipoDocumentoEmissao
      );
    this.series = response.map((s) => ({
      label: `${s.siglaDocAutorizacaoEmissao}${s.numSerieDocumento}${s.anoAutorizacaoEmissao}`,
      value: s.idAutorizacaoEmissao,
    }));
    this.documentForm.patchValue({
      ...response,
    });
  }

  async getLastNumberDocumentByAuthorization(
    authorizationId: number
  ): Promise<void> {
    try {
      const response: DocumentOnline =
        await this.documentOnlineEmitterService.getLastByAuthorizationId(
          authorizationId
        );
      const numeroSequencial: number = +response.numeroSequencial + 1;
      const numDocumentoOnlineConvertido: string = (
        '000000' + numeroSequencial
      ).slice(-6);
      this.documentForm.patchValue({
        numDocumentoOnline: `${numDocumentoOnlineConvertido}`,
      });
    } catch (error) {
      return this.errorHandler.handle(error);
    }
  }

  async getExemptionReasons(taxRate: TaxRate) {
    var isento = taxRate.valorTaxaAplicavel === 0 ? true : false;

    const dataFilter = {
      taxId: taxRate.idTaxaAplicavel,
      documentTypeSigla:
        this.emissionDocumentType.value?.siglaTipoDocumentoEmissao,
    };

    try {
      const response =
        await this.exemptionReasonService.getByTaxIdAndDocumentTypeSigla(
          dataFilter
        );
      if (this.iscTax) {
        this.exemptionReasons = response;
      } else {
        this.exemptionReasons = response.filter(
          (e) => e.isento === isento && e.tbTipoImposto.idTipoImposto === 1
        );
      }
      
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  async getAllTaxRates() {
    try {
      this.taxRates = await this.taxRateService.readActives(this.currentDocumentDate);
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  async getActiveTaxRates() {
    this.taxRateService.readActives(this.currentDocumentDate)
    .then((response) => {
      this.taxRates = response;
    }).catch((error) => {
      if (error.status === 406) {
        this.showFrameworkModal = true;
        return;
      }
      this.errorHandler.handle(error);
    })
  }

  showDisplayExemption(index) {
    this.itemIndex = index;
    this.displayExemption = true;
  }

  showTaxRate(index) {
    this.itemIndex = index;
    this.displayTaxRate = true;
  }

  onExemptionSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbMotivoIsencao: {
        idMotivoIsencao: event.data.idMotivoIsencao,
        codigoMotivoIsencao: event.data.codigoMotivoIsencao,
        desMotivoIsencao: event.data.desMotivoIsencao,
      },
    });
    this.displayExemption = false;
  }

  onTaxRateSelect(event) {
    this.items.at(this.itemIndex).patchValue({
      tbTaxaAplicavel: {
        idTaxaAplicavel: event.data.idTaxaAplicavel,
        descTaxaAplicavel: event.data.descTaxaAplicavel,
        valorTaxaAplicavel: event.data.valorTaxaAplicavel,
      },
    });
    this.getExemptionReasons(event.data);
    this.calcItemTax(this.itemIndex);
    this.displayTaxRate = false;
  }

  calcItemTax(index: number) {
    var m = new Mathematic();

    const itemQuantity = this.items.at(index).get('quantItens').value;
    const itemUnitaryValue = this.items.at(index).get('valorUnitItens').value;
    const itemTaxRate = this.items.at(index).get('tbTaxaAplicavel')
      .value.valorTaxaAplicavel;

    const itemAmount = itemQuantity * itemUnitaryValue;
    const itemTaxValue = m.round((itemAmount * itemTaxRate) / 100, 2);

    this.items.at(index).patchValue({
      valorBaseTributavelItens: itemAmount,
      valorImposto: itemTaxValue,
      totalLinhaAposImpostoItens: itemAmount + itemTaxValue,
    });

    const itemTax = this.items.at(index).get('valorImposto').value;

    this.items.at(index).patchValue({
      valorItens: m.round(itemAmount + itemTax, 2),
    });

    this.calcTax();
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items.at(i).get('valorBaseTributavelItens').value;
      tax += this.items.at(i).get('valorImposto').value;
      total += this.items.at(i).get('totalLinhaAposImpostoItens').value;
    }

    this.documentForm
      .get('totalValorBaseTributavelOnline')
      .patchValue(m.round(amount, 6));
    this.documentForm.get('totalImpostoOnline').patchValue(m.round(tax, 6));
    this.documentForm.get('totalValorOnline').patchValue(m.round(total, 6));
  }

  save() {
    if (this.documentForm.invalid) {
      this.validateForm(this.documentForm);
      return;
    }

    if (this.documentForm.get('dtEmissaoDocumentoOnline').value > new Date()) {
      this.messageService.add({
        severity: 'error',
        detail: 'Informe uma Data de Emissão válida!',
      });
      return;
    }

    this.display = true;
  }

  confirm() {
    this.loading = true;

    this.documentOnlineEmitterService
      .create(this.documentForm.getRawValue())
      .then((response) => {
        const encryptId = encrypt(response.idDocumentoGeradoOnline.toString());
        this.router.navigate(['emitter/document-online/read', encryptId]);

        this.messageService.add({
          severity: 'success',
          detail: 'Documento salvo com sucesso.',
        });
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loading = false));
  }

  encrypt(info: string) {
    return encrypt(info);
  }

  displayFormCreditNote({ siglaTipoDocumentoEmissao: acronym }) {
    if (acronym === 'NC' || acronym === 'ND') {
      this.displayFieldForCreditNote = true;
    } else {
      this.displayFieldForCreditNote = false;
    }
  }

  showDisplayOrigin(index) {
    this.itemIndex = index;
    this.displayOrigin = true;
  }

  onOriginSelect(origin: Document) {
    this.items.at(this.itemIndex).patchValue({
      documentosOrigemOnline: {
        numDocumentoOrigemOnline: origin.numDocumento,
      },
    });

    this.calcItemTax(this.itemIndex);

    this.displayOrigin = false;
  }

  readOriginsByEmitter(page = 0) {
    this.loadingOrigin = true;
    this.filterOrigin.page = page;
    this.documentEmitterService
      .readDocumentOriginsByEmitter(this.filterOrigin)
      .then((response) => {
        this.totalDocumentsOrigin = response.total;
        this.documentsOrigin = response.body;
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => (this.loadingOrigin = false));
  }

  onChangePage(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    this.readOriginsByEmitter(pagina);
  }

  getCodeSituationInTable(exemptionReason: ExemptionReason): string {
    const isExempt: boolean = exemptionReason.isento;
    if (isExempt) {
      if (
        exemptionReason.codigoMotivoIsencao == 113 ||
        exemptionReason.codigoMotivoIsencao == 114 ||
        exemptionReason.codigoMotivoIsencao == 313 ||
        exemptionReason.codigoMotivoIsencao == 314
      ) {
        return 'Regime Especial';
      } else {
        return 'Isenção';
      }
    } else {
      return 'Sujeição';
    }
  }

  validateForm(formGroup: UntypedFormGroup | UntypedFormArray) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateForm(control);
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((formGroup) => {
          this.validateForm(formGroup as UntypedFormGroup);
        });
      }
    });
  }
}
