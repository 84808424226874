import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { decrypt } from '../../configs/encryption';
import { DocumentOnlineEmitterService } from '../document-online-emitter.service';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { Authorization } from './../../core/models/authorization';
import { Certification } from './../../core/models/certification';
import { DocumentOnlineView } from './../../core/models/document-online';
import { TaxPayer } from './../../core/models/taxpayer';
import { TaxpayerWebService } from './../../core/models/taxpayer-webservice';
import { TypeTax } from './../../core/models/type-tax';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { encrypt } from '../../configs/encryption';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth/auth.service';
import { TaxRateService } from 'src/app/tax-rate/tax-rate.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss'],
})
export class GetComponent implements OnInit {
  invoice = new DocumentOnlineView();
  typeTax = new TypeTax();
  taxpayer = new TaxPayer();
  emitter = new TaxPayer();
  authorization = new Authorization();
  certification = new Certification();
  consumer = new TaxpayerWebService();

  documentType: string;
  loading: boolean = false;

  siglaTipoImpostoToShow: string;
  taxTypeRegimeCode: number;

  isAlc = false;

  constructor(
    private service: DocumentOnlineEmitterService,
    private contribuinteService: TaxpayerService,
    private errorHandler: ErrorHandlerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
  }

  verifyIfItemsIncludeAlcCode(documentItems:any[]) {
    const items = documentItems;
    const isAlc = items.some(
      (item) => item?.tbMotivoIsencaoCodigo === "153"
    );

    this.isAlc = isAlc;
  }

  goBack(): void {
    this.location.back();
  }

  get(id: number) {
    this.service
      .getDTOById(id)
      .then(async (response) => {
        // try {
        //   const userNif = this.authService.getLoggedUser().preferred_username;
        //   const taxTypeResult: any = await this.taxRateService.getIvaTaxRegime(userNif);
        //   if (taxTypeResult.data && taxTypeResult.data.length > 0) {
        //     this.taxTypeRegimeCode = taxTypeResult.data[0].codigo;
        //   }
        // } catch (e) {

        // }
        this.invoice = response;
        this.documentType = response.tbAutorizacaoEmissaoSigla;

        const documentDate = new Date(this.invoice.dtEmissaoDocumento);
        this.verifyIfItemsIncludeAlcCode(this.invoice.itensDocumentoGeradoOnlinesDto);
        this.showTaxSigla(documentDate);
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  getSpecialRegimeDocumentType() {
    return this.taxTypeRegimeCode === 1116220 ? '2%' : (this.taxTypeRegimeCode === 1116210 ? '7%' : null);
  }

  getConsumerByNif(nif: string) {
    if (nif !== null) {
      this.contribuinteService
        .getByNifInWebService(nif)
        .then((response) => (this.consumer = response))
        .catch((error) => this.errorHandler.handle(error));
    }
  }

  showTaxSigla(documentDate: Date) {
    const IvaStartDate: Date = new Date(2023, 5, 1);
    this.siglaTipoImpostoToShow = documentDate >= IvaStartDate ? 'IVA' : 'ISC';
  }

  confirmCancelDialog() {
    this.confirmationService.confirm({
      message: `Será gerada uma nota de crédito para anulação total deste documento. Esse procedimento é irreversível. Deseja prosseguir?`,
      accept: () => {
        this.cancelDocument();
      },
    });
  }

  cancelDocument() {
    this.loading = true;
    this.service
      .cancelDocument(this.invoice.idDocumentoGeradoOnline)
      .then((result) => {
        this.messageService.add({
          severity: 'success',
          detail:
            'Documento cancelado com sucesso. Foi emitida uma nota de crédito para efetivar o cancelamento.',
          life: 10000,
        });
        this.routerLink(result.idDocumentoGeradoOnline);
      })
      .catch((err) => this.errorHandler.handle(err))
      .finally(() => (this.loading = false));
  }

  routerLink(id: number) {
    const encryptedId = encrypt(id.toString());
    this.router.navigate(['/emitter/document-online/read/nc', encryptedId]);
  }
}
