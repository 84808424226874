import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { encrypt } from '../../configs/encryption';
import { Mathematic } from './../../configs/models/math';
import { ErrorHandlerService } from './../../core/error-handler.service';
import { DocumentConsumerForm } from './../../core/models/document-consumer';
import { TaxpayerService } from './../../taxpayer/taxpayer.service';
import { DocumentConsumerRegConsumerService } from './../document-consumer-reg-consumer.service';
import { TaxRate } from 'src/app/core/models/tax-rate';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload;
  loading: boolean = false;
  validNif: boolean = true;

  documentForm = new DocumentConsumerForm(this.fb).builder();
  documentItemForm = new DocumentConsumerForm(this.fb);

  siglaTipoImpostoToShow = 'IVA';
  math = new Mathematic();

  taxRates = [];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private taxPayerService: TaxpayerService,
    private errorHandler: ErrorHandlerService,
    private documentConsumerRegConsumerService: DocumentConsumerRegConsumerService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.taxRates = [
      {
        label: '15%',
        value: 15,
      },
      {
        label: '7,5%',
        value: 7.5,
      },
      {
        label: 'Sem Taxa Aplicada',
        value: 0,
      },
    ];
    this.documentForm
      .get('totalValorBaseTributavel')
      .valueChanges.subscribe((value) => {
        const taxtValue = this.documentForm.get('totalImposto').value;
        this.documentForm
          .get('totalValor')
          .patchValue(this.math.round(taxtValue + value, 2));
      });

    this.documentForm.get('totalImposto').valueChanges.subscribe((value) => {
      const baseValue = this.documentForm.get('totalValorBaseTributavel').value;
      this.documentForm
        .get('totalValor')
        .patchValue(this.math.round(baseValue + value, 2));
    });

    this.documentForm
      .get('nifConsumidor')
      .patchValue(this.authService.getLoggedUser().preferred_username);
  }

  onInputNif(event) {
    const nif = event.target.value;
    if (nif.length === 9) {
      this.taxPayerService
        .getByNifInWebService(nif)
        .then((response) => {
          this.documentForm.get('nomeEmissor').patchValue(response.contriNome);
          this.validNif = true;
        })
        .catch(() => {
          this.validNif = false;
          this.documentForm.get('nomeEmissor').patchValue(null);
        });
    } else {
      this.validNif = false;
      this.documentForm.get('nomeEmissor').patchValue(null);
    }
  }

  showTaxSigla(documentDate: any) {
    const IvaStartDate: Date = new Date(2023, 5, 1);
    this.siglaTipoImpostoToShow =
      documentDate >= IvaStartDate || !documentDate ? 'IVA' : 'ISC';
  }

  save() {
    this.loading = true;
    this.documentConsumerRegConsumerService
      .create(this.documentForm.getRawValue())
      .then((response) => {
        this.messageService.add({
          severity: 'success',
          detail:
            'Documento comunicado! Receberá uma notificação após a validação do mesmo',
        });
        this.clearImage();
        this.documentForm.reset();
        this.documentForm.get('totalImposto').patchValue(0);
        this.documentForm.get('totalValor').patchValue(0);
        this.documentForm.get('totalValorBaseTributavel').patchValue(0);
        setTimeout(() => {
          this.router.navigate([
            `/consumer/document-consumer/read/${encrypt(
              `${response.idDocumentoGeradoConsumidor}`
            )}`,
          ]);
        }, 3000);
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => {
        this.loading = false;
      });
  }

  calcTax() {
    var m = new Mathematic();
    var amount = 0;
    var tax = 0;
    var total = 0;

    for (var i = 0; i < this.items.length; i++) {
      amount += this.items.at(i).get('valorBaseTributavelItens').value;
      tax += this.items.at(i).get('valorImposto').value;
      total += this.items.at(i).get('valorItens').value;
    }

    this.documentForm
      .get('totalValorBaseTributavel')
      .patchValue(m.round(amount, 2));
    this.documentForm.get('totalImposto').patchValue(m.round(tax, 2));
    this.documentForm.get('totalValor').patchValue(m.round(total, 2));
  }

  onTaxRateSelect(event, itemIndex: number) {
    this.calcItemTax(itemIndex);
  }

  // DOCUMENT ITEMS FEATURES

  get items(): FormArray {
    return this.documentForm.get('items') as FormArray;
  }

  addItem() {
    this.items.push(this.documentItemForm.buildItem());
  }

  removeItem(i: number) {
    if (this.items.controls.length > 1) {
      this.items.removeAt(i);
      this.calcTax();
    }
  }

  calcItemTax(index: number) {
    const itemAmount = this.items
      .at(index)
      .get('valorBaseTributavelItens').value;

    const itemTaxRate = this.items.at(index).get('valorTaxaAplicavel').value;

    var m = new Mathematic();
    this.items.at(index).patchValue({
      valorImposto: m.round((itemAmount * itemTaxRate) / 100, 2),
    });

    const itemTax = this.items.at(index).get('valorImposto').value;

    this.items.at(index).patchValue({
      valorItens: m.round(itemAmount + itemTax, 2),
    });

    this.calcTax();
  }

  // FILE FEATURES

  public onSelectImage(evt: any) {
    this.documentForm.get('files').patchValue(this.fileUpload.files);
  }

  private clearImage() {
    this.fileUpload.clear();
    this.onDeleteImage();
  }

  public onDeleteImage() {
    this.documentForm.get('files').patchValue(this.fileUpload.files);
  }
}
