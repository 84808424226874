import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Locale } from 'src/app/configs/models/locale';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import {
  DocumentConsumerRegistration,
  DocumentConsumerRegistrationForm,
  DocumentConsumerRegistrationStatus,
} from 'src/app/core/models/document-consumer-registration';
import { ExemptionReason } from 'src/app/core/models/exemption-reason';
import { FileDocument } from 'src/app/core/models/file-document';
import { DocumentConsumerRegConsumerService } from 'src/app/document-consumer-reg-consumer/document-consumer-reg-consumer.service';
import { ExemptionReasonService } from 'src/app/exemption-reason/exemption-reason.service';
import { FileDocumentEmitterService } from 'src/app/file-document-emitter/file-document-emitter.service';
import { DocumentConsumerRegService } from '../document-consumer-reg.service';
import { Document, DocumentView } from 'src/app/core/models/document';
import {
  DocumentFilter,
  DocumentService,
} from 'src/app/document/document.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss'],
})
export class GetComponent implements OnInit {
  locale = new Locale();
  document = new DocumentConsumerRegistration();
  documentForm = new DocumentConsumerRegistrationForm(this.fb).builder();
  loading = false;
  validDocumentLoading = false;
  displayExemption = false;
  exemptionReasons: ExemptionReason[];
  showValidationModal = false;
  files = [];
  siglaTipoImpostoToShow: string;
  documentStatus: DocumentConsumerRegistrationStatus;
  finalDocument: DocumentView;

  canAccept = false;

  constructor(
    private documentConsumerRegService: DocumentConsumerRegService,
    private documentConsumerRegConsumerService: DocumentConsumerRegConsumerService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private exemptionReasonService: ExemptionReasonService,
    private fileService: FileDocumentEmitterService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    const id: number = this.route.snapshot.params.id;
    this.get(id);
    this.getExemptionReasons();
    this.getFile(id);
  }

  canValidateDocument(
    documentStatus: DocumentConsumerRegistrationStatus
  ): boolean {
    return (
      documentStatus === DocumentConsumerRegistrationStatus.APROVADO ||
      documentStatus === DocumentConsumerRegistrationStatus.RECUSADO
    );
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  showTaxSigla(documentDate: any) {
    const IvaStartDate: Date = new Date(2023, 6, 1);
    this.siglaTipoImpostoToShow =
      documentDate >= IvaStartDate || !documentDate ? 'IVA' : 'ISC';
  }

  toggleValidationModal(value: boolean): void {
    this.showValidationModal = value;
  }

  onExemptionSelect(event) {
    this.documentForm.get('tbMotivoIsencao').patchValue({
      idMotivoIsencao: event.data.idMotivoIsencao,
      codigoMotivoIsencao: event.data.codigoMotivoIsencao,
      desMotivoIsencao: event.data.desMotivoIsencao,
    });
    this.displayExemption = false;
  }

  showDisplayExemption() {
    this.displayExemption = true;
  }

  async getExemptionReasons() {
    try {
      const response: ExemptionReason[] =
        await this.exemptionReasonService.getAll();
      this.exemptionReasons = response.filter(
        (reason) => reason.tbTipoImposto.idTipoImposto === 1
      );
    } catch (erro) {
      return this.errorHandler.handle(erro);
    }
  }

  getFile(id: number) {
    this.documentConsumerRegService
      .getFile(id)
      .then((response) => {
        this.files = response;
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  downloadFile(file: FileDocument) {
    this.fileService.getConsumerFile(file).then((response) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = file.nomeArquivoDocumentoGerado;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(blobUrl);
    });
  }

  get(id: number) {
    this.documentConsumerRegService
      .get(id)
      .then((response) => {
        this.document = response;
        this.documentForm.patchValue({
          ...response,
          serie:
            this.document.serie ||
            this.document.numDocumento?.substring(0, 9) ||
            null,
          numero:
            this.document.numero || this.document.numero?.substring(9) || null,
        });
        this.documentForm.patchValue({
          desDocumentoGeradoConsumidor: response.desDocumentoGeradoConsumidor,
        });
        this.getDocumentFinalByReference(response?.idFinal);
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  accept(document: DocumentConsumerRegistration) {
    this.validDocumentLoading = true;
    this.messageService.clear();

    document.statusDocumentoGeradoConsumidor =
      DocumentConsumerRegistrationStatus.APROVADO;

    this.documentConsumerRegService
      .validate(document)
      .then((response) => {
        this.document = response;
        this.documentForm.patchValue({
          ...response,
        });
        this.getDocumentFinalByReference(response?.idFinal);
        this.messageService.add({
          severity: 'success',
          detail: 'Documento validado com sucesso!',
        });
        this.toggleValidationModal(false);
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => {
        this.validDocumentLoading = false;
      });
  }

  refuse(document) {
    this.validDocumentLoading = true;
    this.messageService.clear();

    document.statusDocumentoGeradoConsumidor =
      DocumentConsumerRegistrationStatus.RECUSADO;

    this.documentConsumerRegService
      .validate(document)
      .then((response) => {
        this.documentForm.patchValue({
          ...response,
        });
        this.messageService.add({
          severity: 'success',
          detail: 'Documento recusado com sucesso!',
        });
        this.toggleValidationModal(false);
      })
      .catch((error) => this.errorHandler.handle(error))
      .finally(() => {
        this.validDocumentLoading = false;
      });
  }

  verify() {
    this.loading = true;

    this.documentForm.get('desDocumentoGeradoConsumidor').reset();

    this.messageService.clear();

    this.documentConsumerRegService
      .verify(this.documentForm.getRawValue())
      .then((response) => {
        this.canAccept = true;
        this.messageService.add({
          severity: 'success',
          detail: response?.mensage,
        });
        this.documentForm
          .get('desDocumentoGeradoConsumidor')
          .patchValue(response?.mensage);
      })
      .catch((error) => {
        if (error.status === 409) {
          const documentNumber = error.error?.numDocumento;
          const errorMessage = `O documento ${documentNumber} já foi comunicado pelo seu emitente/fornecedor. Por favor verificar na lista de documentos comunicados pelo Emitente.`;

          this.messageService.add({
            severity: 'error',
            detail: errorMessage,
          });

          this.documentForm
            .get('desDocumentoGeradoConsumidor')
            .patchValue(errorMessage);
        } else if (error.status === 400) {
          this.canAccept = false;
          const errorMessage = error.error[0]?.mensagemUsuario;

          this.messageService.add({
            severity: 'error',
            detail: errorMessage,
          });

          this.documentForm
            .get('desDocumentoGeradoConsumidor')
            .patchValue(errorMessage);
        } else {
          this.errorHandler.handle(error);
        }
        this.canAccept = false;
      })
      .finally(() => {
        this.loading = false;
        this.toggleValidationModal(true);
      });
  }

  getDocumentStatusColor(data: DocumentConsumerRegistration) {
    return this.documentConsumerRegConsumerService.getDocumentValidationStatusColor(
      data.statusDocumentoGeradoConsumidor
    );
  }

  getDocumentFinalByReference(finalDocumentId: number) {
    if (finalDocumentId) {
      this.documentService.get(finalDocumentId).then((response) => {
        this.finalDocument = response;
      });
    }
  }
}
