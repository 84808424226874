import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from './../../environments/environment';
import { AppHttp } from './../auth/app-http';
import { Document, DocumentState, DocumentView } from './../core/models/document';

export class DocumentEmitterFilter {
  page = 0;
  rows = 15;

  minRows = 8;

  nifEmissor: string;
  nifConsumidor: string;
  numDocumento: string;
  numSerieDocumento: string;
  dtEmissaoDocumentoDe: Date;
  dtEmissaoDocumentoAte: Date;

  editable: Boolean = null;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentEmitterService {
  API_URL: string;

  constructor(private http: AppHttp) {
    this.API_URL = `${environment.apiUrl}/documentoGerado`;
  }

  create(document: any): Promise<Document> {
    delete document.origem;

    for (let i = 0; i < document.tbItensDocumentoGerados.length; i++) {
      delete document.tbItensDocumentoGerados[i].totalOrigin;

      if (![3, 4].includes(document.tbTipoDocumentoEmissao.idTipoDocumentoEmissao)) {
        delete document.tbItensDocumentoGerados[i].tbDocumentoOrigems;
      }
    }

    delete document.tbTipoDocumentoEmissao;
    delete document.nomeConsumidor;
    delete document.nifEmissor;
    delete document.nomeEmissor;

    return this.http.post<Document>(`${this.API_URL}/emissor`, document)
      .toPromise();
  }

  async read_(filter: DocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.editable !== null)
      params = params.append('btEditavel', filter.editable.toString());

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(`${this.API_URL}/porEmissor`, { params })
      .toPromise();
    return {
      body: <Document[]>response.content,
      total: response.totalElements
    };
  }

  async readState(filter: DocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.editable !== null)
      params = params.append('btEditavel', filter.editable.toString());

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(`${this.API_URL}/estado/fornecedor`, { params })
      .toPromise();
    return {
      body: <Document[]>response.content,
      total: response.totalElements
    };
  }

  async readIssues(filter: DocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.rows.toString());

    if (filter.nifConsumidor)
      params = params.append('nifConsumidor', filter.nifConsumidor);

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    if (filter.numSerieDocumento)
      params = params.append('numSerieDocumento', filter.numSerieDocumento);

    if (filter.editable !== null)
      params = params.append('btEditavel', filter.editable.toString());

    if (filter.dtEmissaoDocumentoDe)
      params = params.append('dtEmissaoDocumentoDe', moment(filter.dtEmissaoDocumentoDe).format('YYYY-MM-DD'));

    if (filter.dtEmissaoDocumentoAte)
      params = params.append('dtEmissaoDocumentoAte', moment(filter.dtEmissaoDocumentoAte).format('YYYY-MM-DD'));

    const response = await this.http.get<any>(`${this.API_URL}/porEmissor/editavel`, { params })
      .toPromise();
    return {
      body: <Document[]>response.content,
      total: response.totalElements
    };
  }

  async get(id): Promise<Document> {
    const response = await this.http.get<Document>(`${this.API_URL}/porEmissor/${id}`)
      .toPromise();
    const document = response as Document;
    this.convertFields([document]);
    return document;
  }

  async getState(id): Promise<DocumentState> {
    const response = await this.http.get<DocumentState>(`${this.API_URL}/estado/${id}`)
      .toPromise();
    const document = response as DocumentState;
    this.convertFieldsState([document]);
    return document;
  }

  async readDocumentOriginsByEmitter(filter: DocumentEmitterFilter): Promise<any> {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.minRows.toString());

    if (filter.numDocumento)
      params = params.append('numDocumento', filter.numDocumento);

    const response = await this.http.get<any>(`${this.API_URL}/nifEmitente`, { params })
      .toPromise();
    return {
      body: <any[]>response.content,
      total: response.totalElements
    };
  }

  async getTotalCreditNote(id: number): Promise<Document> {
    const response = await this.http.get<any>(`${this.API_URL}/valoresTotais/${id}`)
      .toPromise();
    return response;
  }

  updateNumber(document: Document): Promise<any> {
    return this.http.put<any>(`${this.API_URL}/atualizarNumero/${document.idDocumentoGerado}`, document)
      .toPromise()
  }

  async update(document: Document): Promise<any> {
    delete document.canceledAmount;

    const response = await this.http.put<any>(`${this.API_URL}/porEmissor`, document)
      .toPromise();
    const documentUpdated = response as Document;
    this.convertFields([documentUpdated]);
    return documentUpdated;
  }

  delete(id: number): Promise<any> {
    return this.http.delete(`${this.API_URL}/${id}`)
      .toPromise();
  }

  private convertFields(documents: Document[]) {
    for (const document of documents) {
      document.canceledAmount =
        document.totalValorBaseTributavel - document.valorDisponivelAnular;

      if (document.dtAlter) {
        document.dtAlter = moment(document.dtAlter,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtCria) {
        document.dtCria = moment(document.dtCria,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtEmissaoDocumento) {
        document.dtEmissaoDocumento = moment(document.dtEmissaoDocumento,
          'YYYY-MM-DD').toDate();
      }
    }
  }

  private convertFieldsState(documents: DocumentState[]) {
    for (const document of documents) {
      document.canceledAmount =
        document.baseTributavel - document.disponivelAnular;

      if (document.dtAlter) {
        document.dtAlter = moment(document.dtAlter,
          'YYYY-MM-DD').toDate();
      }

      if (document.dtCria) {
        document.dtCria = moment(document.dtCria,
          'YYYY-MM-DD').toDate();
      }

      if (document.dataEmissao) {
        document.dataEmissao = moment(document.dataEmissao,
          'YYYY-MM-DD').toDate();
      }
    }
  }
}
