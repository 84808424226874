import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from 'src/app/configs/models/locale';
import { decrypt } from '../../configs/encryption';
import { DocumentConsumerRegService } from './../../document-consumer-reg/document-consumer-reg.service';
import { ErrorHandlerService } from 'src/app/core/error-handler.service';
import { FileDocument } from 'src/app/core/models/file-document';
import { FileDocumentEmitterService } from 'src/app/file-document-emitter/file-document-emitter.service';
import { DocumentConsumerRegistration } from 'src/app/core/models/document-consumer-registration';
import { DocumentConsumerRegConsumerService } from '../document-consumer-reg-consumer.service';

@Component({
  selector: 'app-get',
  templateUrl: './get.component.html',
  styleUrls: ['./get.component.scss'],
})
export class GetComponent implements OnInit {
  document = new DocumentConsumerRegistration();
  locale = new Locale();
  files = [];
  siglaTipoImpostoToShow: string;

  constructor(
    private service: DocumentConsumerRegService,
    private documentConsumerRegConsumerService: DocumentConsumerRegConsumerService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private fileService: FileDocumentEmitterService
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const decryptedId = Number(decrypt(id));
    this.get(decryptedId);
    this.getFile(decryptedId);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  showTaxSigla(documentDate: any) {
    const IvaStartDate: Date = new Date(2023, 6, 1);
    this.siglaTipoImpostoToShow =
      documentDate >= IvaStartDate || !documentDate ? 'IVA' : 'ISC';
  }

  getFile(id: number) {
    this.service
      .getFile(id)
      .then((response) => {
        this.files = response;
      })
      .catch((error) => this.errorHandler.handle(error));
  }

  downloadFile(file: FileDocument) {
    this.fileService.getConsumerFile(file).then((response) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = file.nomeArquivoDocumentoGerado;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(blobUrl);
    });
  }

  get(id: number) {
    this.service.get(id).then((response) => {
      this.document = response;
      this.showTaxSigla(this.document.dtEmissaoDocumento);
    });
  }

  getDocumentStatusColor(data: DocumentConsumerRegistration) {
    return this.documentConsumerRegConsumerService.getDocumentValidationStatusColor(
      data.statusDocumentoGeradoConsumidor
    );
  }
}
