import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Document } from 'src/app/core/models/document';

@Component({
  selector: 'app-document-consumer-reg-elements-after-validation',
  templateUrl: './document-consumer-reg-elements-after-validation.component.html',
  styleUrls: ['./document-consumer-reg-elements-after-validation.component.scss']
})
export class DocumentConsumerRegElementsAfterValidationComponent implements AfterViewInit {
  @Input() document: Document;

  numDocumento: string;
  totalValorBaseTributavel: number;
  totalImposto: number;
  totalValor: number;

  constructor() { }

  ngAfterViewInit(): void {
    this.totalValorBaseTributavel = this.document.totalValorBaseTributavel;
    this.totalImposto = this.document.totalImposto;
    this.totalValor = this.document.totalValor;
    this.numDocumento = this.document.numDocumento;
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  get items() {
    return this.document?.tbItensDocumentoGerados;
  }
}
