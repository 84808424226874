<div class="p-grid">
  <div class="p-col-3 p-field">
    <label>Número Documento</label>
    <input pInputText name="numDocumento" [(ngModel)]="numDocumento" disabled />
  </div>
  <div class="p-col-12">
    <hr />
    <div class="p-grid p-mt-2">
      <label class="p-col-fixed" [ngStyle]="{ width: '450px' }">
        Descrição
      </label>

      <label class="p-col">
        Subtotal
      </label>

      <label class="p-col">
        Taxa (IVA)
      </label>

      <label class="p-col"> IVA </label>

      <label class="p-col"> Total </label>
    </div>

    <div
      class="p-grid"
      *ngFor="let item of items; let i = index; trackBy: trackByIndex"
    >
      <div class="p-field p-col">
        <p-inputNumber
          mode="decimal"
          [minFractionDigits]="2"
          name="valorBaseTributavelItens"
          [(ngModel)]="items[i].valorBaseTributavelItens"
          [ngModelOptions]="{ standalone: true }"
          class="p-inputtext-sm"
          #valorBaseTributavelItens="ngModel"
          disabled
        >
        </p-inputNumber>
      </div>

      <div class="p-field p-col">
        <input
          pInputText
          class="p-inputtext-sm"
          name="descTaxaAplicavel"
          [(ngModel)]="items[i].tbTaxaAplicavelDescricao"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Taxa"
          disabled
        />
      </div>

      <div class="p-field p-col">
        <p-inputNumber
          class="p-inputtext-sm"
          mode="decimal"
          [minFractionDigits]="2"
          name="valorImposto"
          [(ngModel)]="items[i].valorImposto"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Imposto"
          disabled
        >
        </p-inputNumber>
      </div>

      <div class="p-field p-col">
        <p-inputNumber
          class="p-inputtext-sm"
          mode="decimal"
          [minFractionDigits]="2"
          name="valorItens"
          [(ngModel)]="items[i].totalLinhaAposImpostoItens"
          [ngModelOptions]="{ standalone: true }"
          disabled
        >
        </p-inputNumber>
      </div>
    </div>
    <hr />
  </div>

  <div class="p-field p p-col-12 p-md-4">
    <label>Montante</label>
    <p-inputNumber
      class="p-inputtext-sm"
      mode="decimal"
      [minFractionDigits]="2"
      name="totalValorBaseTributavel"
      [(ngModel)]="totalValorBaseTributavel"
      disabled
    >
    </p-inputNumber>
  </div>

  <div class="p-field p-col-12 p-md-4">
    <label>IVA</label>
    <p-inputNumber
      class="p-inputtext-sm"
      mode="decimal"
      [minFractionDigits]="2"
      name="totalImposto"
      [(ngModel)]="totalImposto"
      disabled
    >
    </p-inputNumber>
  </div>

  <div class="p-field p-col-12 p-md-4">
    <label>Valor Total</label>
    <p-inputNumber
      class="p-inputtext-sm"
      mode="decimal"
      [minFractionDigits]="2"
      name="totalValor"
      [(ngModel)]="totalValor"
      disabled
    >
    </p-inputNumber>
  </div>
</div>
