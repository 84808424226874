import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DocumentConsumerItem } from './document-consumer-item';
import { TypeDocument } from './type-document';

export class DocumentConsumer {
  idDocumentoGeradoConsumidor: number;
  numDocumento: string;
  nifConsumidor: string;
  nomeConsumidor: string;
  nifEmissor: string;
  nomeEmissor: string;
  dtEmissaoDocumento: Date;
  totalValor: string;
  totalImposto: string;
  totalValorBaseTributavel: string;

  dtAlter: Date;
  dtCria: Date;
  criaIdUsuario: string;
  alterIdUsuario: string;

  tbTipoDocumentoEmissao = new TypeDocument();
  tbItensDocumentoGeradoConsumidor: DocumentConsumerItem[];
  files: File[];
}

export class DocumentConsumerForm {
  constructor(private fb: FormBuilder) {}

  buildItem(): FormGroup {
    return this.fb.group({
      descItens: new FormControl(),
      valorBaseTributavelItens: new FormControl<number>(
        null,
        Validators.required
      ),
      valorTaxaAplicavel: new FormControl(null, Validators.required),
      valorImposto: new FormControl<number>(
        { value: 0, disabled: true },
        Validators.required
      ),
      valorItens: new FormControl<number>(
        { value: 0, disabled: true },
        Validators.required
      ),
    });
  }

  builder(): FormGroup {
    return this.fb.group({
      numDocumento: new FormControl(null, Validators.required),
      nifEmissor: new FormControl(null, Validators.required),
      nifConsumidor: new FormControl(null),
      nomeEmissor: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      dtEmissaoDocumento: new FormControl(new Date(), Validators.required),
      totalValorBaseTributavel: new FormControl(0, [
        Validators.min(1),
        Validators.required,
      ]),
      totalImposto: new FormControl(0, Validators.required),
      totalValor: new FormControl(
        { value: 0, disabled: true },
        Validators.required
      ),
      files: new FormControl(null, Validators.required),
      items: this.fb.array([this.buildItem()]),
    });
  }
}
